import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer } from 'preact-i18n';

import GuiInfoBox from '../../components/gui/infoBox';

@observer
class PaypalGeneric extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPayment: null,
        };
    }

    loadAll = async (props = this.props) => {
        const { vippsStore } = this.props.stores;
        vippsStore.updateKeyValue('redirectUrl', null);
    }

    paypalRequestPayment = async () => {
        const { currency, amount, title, type, customer, race, raceClassContestant, callback = () => {} } = this.props;

        const { paypalAmount } = this.state;
        const { paypalStore } = this.props.stores;
        const response = await paypalStore.requestPayment({
            currency,
            amount,
            title,
            type,
            customer,
            race,
            raceClassContestant,
        });

        if (response?.data) {
            callback(response?.data);

            if (response.data.redirectUrl) {
                window.open(response.data.redirectUrl, '_blank');
                this.checkPaymentStatus(response.data.paymentUuidv4);
            }
        }
    }

    checkPaymentStatus = async (uuidv4) => {
        const { currency, amount, title, type, race, raceClassContestant } = this.props;
        const { callbackPaymentDone = () => {} } = this.props;
        const { paymentStore } = this.props.stores;
        const currentPayments = await paymentStore.load({ query: { uuidv4 }, skipUpdate: true });
        const currentPayment = currentPayments?.[0];
        this.setState({ currentPayment });

        if (currentPayment.paymentStatus < 3) {
            this.timer = setTimeout(this.checkPaymentStatus, 5000, uuidv4);
        } else {
            callbackPaymentDone({ currency, amount, title, type, paymentUuidv4: uuidv4, race, raceClassContestant });
        }
    }

    componentDidMount() {
        this.loadAll();
    }

    render() {
        const { currency, amount, title, type } = this.props;
        const { currentPayment = {} } = this.state;
        const { paypalStore, paymentStore } = this.props.stores;
        const { redirectUrl } = paypalStore;

        const progress = {
            0: 0,
            1: 33,
            2: 66,
            3: 100,
        };

        return (
			<div class='w-100 d-flex justify-content-center'>
                <div class='w-100 h-100' style='max-width: 960px; padding-top: 20px; padding-bottom: 20px;'>
                    {/* currency: {currency}
                    amount: {amount}
                    title: {title}
                    type: {type} */}

                    <div class='d-flex flex-row justify-content-center mt-0'>
                        <GuiInfoBox
                            stores={this.props.stores}
                            nameMiddle={title}
                            value={util.format(amount, 0)}
                            postfix={'NOK'}
                            styles={`font-size: 2.5em; line-height: 1.0em;`}
                        />
                    </div>

                    {currentPayment && redirectUrl ? <>

                        {currentPayment.paymentStatus === 1 && <>
                            <div class='d-flex flex-column'>
                                <div class='d-flex flex-row justify-content-center display-1'>
                                    <i class='fa-duotone fa-spinner-third fa-spin' />
                                </div>
                                <div class='d-flex flex-row justify-content-center mt-3'>
                                    <nobr><Text id='payment.waiting-for-paypal-payment'>Waiting for PayPal payment. Please check your PayPal app.</Text></nobr>
                                </div>
                                <div class='d-flex flex-row justify-content-center mt-3'>
                                    <a href={redirectUrl} target='_blank' class='btn btn-sm btn-outline-primary rounded-pill ml-2'>
                                        <nobr><i class='fa-duotone fa-arrow-up-right-from-square' /> Open PayPal</nobr>
                                    </a>
                                </div>
                            </div>
                        </>}

                        {currentPayment.paymentStatus === 2 && <>
                            <div class='d-flex flex-column'>
                                <div class='d-flex flex-row justify-content-center display-1'>
                                    <i class='fa-duotone fa-circle-dot fa-bounce' />
                                </div>
                                <div class='d-flex flex-row justify-content-center mt-3'>
                                    <nobr><Text id='payment.paypal-payment-authorized-by-user'>Payment is authorized by PayPal.</Text></nobr>
                                </div>
                            </div>
                        </>}

                        {currentPayment.paymentStatus === 3 && <>
                            <div class='d-flex flex-column'>
                                <div class='d-flex flex-row justify-content-center display-1 text-success'>
                                    <i class='fa-duotone fa-check fa-beat' />
                                </div>
                                <div class='d-flex flex-row justify-content-center mt-3'>
                                    <nobr><Text id='payment.paypal-payment-done'>Payment is done.</Text></nobr>
                                </div>
                            </div>
                        </>}
                    </> : <>
                        <div class='d-flex flex-row justify-content-center mt-0'>
                            <button class='btn btn-primary rounded-pill' onClick={this.paypalRequestPayment}>
                                <nobr><i class='fa-duotone fa-credit-card' /> <Text id='payment.pay-with-paypal'>Pay with PayPal</Text></nobr>
                            </button>
                        </div>
                    </>}

                    <div class='d-flex flex-row mt-5'>
                        <div class='progress-bar-container'>
                            <div class='progress-label-container'>
                                <div class='progress-label'>
                                    <div class='progress-label-text'>Start</div>
                                </div>
                                <div class='progress-label'>
                                    <div class='progress-label-text'>PayPal</div>
                                </div>
                                <div class='progress-label'>
                                    <div class='progress-label-text'>Approved</div>
                                </div>
                                <div class='progress-label'>
                                    <div class='progress-label-text'>Paid</div>
                                </div>
                            </div>
                            <div class='progress'>
                                <div class='progress-bar' style={`width: ${progress[currentPayment?.paymentStatus || 0]}%`} aria-valuenow='25' aria-valuemin='0' aria-valuemax='100'></div>
                            </div>
                            <div class='progress-dot-container'>
                                <div class={`progress-dot ${!currentPayment || currentPayment?.paymentStatus >= 0 ? 'active' : ''}`}>&nbsp;</div>
                                <div class={`progress-dot ${currentPayment?.paymentStatus >= 1 ? 'active' : ''}`}>&nbsp;</div>
                                <div class={`progress-dot ${currentPayment?.paymentStatus >= 2 ? 'active' : ''}`}>&nbsp;</div>
                                <div class={`progress-dot ${currentPayment?.paymentStatus >= 3 ? 'active' : ''}`}>&nbsp;</div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}

export default PaypalGeneric;
