import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';
import Markdown from 'preact-markdown';

import localUtil from '../../lib/util';
import fields from '../../lib/fields';

import FormFields from '../form/fields';
import Input from '../form/input';

@withText(props => ({
    orderNumber: <Text id='input.orderNumber-help'>orderNumber</Text>,
    name: <Text id='input.name-help'>name</Text>,
    description: <Text id='input.description-help'>description</Text>,
    installation: <Text id='input.installation-help'>installation</Text>,
    url: <Text id='input.url-help'>url</Text>,
    searchTitle: <Text id='input.search-for-visitor'>Search for visitor</Text>,

    mergeInfo: <Text id='dyrejournal.merge-info'>This is what is going to happend: 1. All animals are transferred to this visitor. 2. All sales are transferred to this visitor. 3. All journal references are transferred to this visitor. 4. Visitor you choose to merge is deleted.</Text>
}))
@observer
class NewVisitor extends Component {
  	constructor(props) {
        super(props);
        this.state = {
            sections: fields.get('visitor'),
        };
    }

    createVisitor = async () => {
        const { callback = () => {} } = this.props;
        const { visitorStore } = this.props.stores;
        const { newVisitor } = visitorStore;
        const response = await visitorStore.insert(newVisitor);
        const visitor = response?.data?.visitor;
        visitorStore.updateKeyValue('newVisitor', {});
        if (response.status === 201) {
            callback(visitor);
        }
    }

    componentDidMount() {
        const { id, isNew } = this.props;
        const { visitorStore } = this.props.stores;
        if (!isNew) {
            visitorStore.load(id);
        }
    }


    searchInput = (e) => {
        const search = e.target.value;
        this.setState({ search });

        clearTimeout(this.searchTimer);
        if (search.length >= 3) {
            this.searchTimer = setTimeout(async () => {
                this.doSearch(search);
            }, 300);
        }
    }

    async doSearch(search) {
        const { visitorStore } = this.props.stores;
        if (search) {
            // console.log('doUpdateField.saveField', id, field, value);
            const result = await visitorStore.searchVisitors({ search });
        } else {
            visitorStore.localUpdateField('searchResult', []);
        }
    }

    clearSearch = () => {
        this.setState({ search: '' });
        this.doSearch('');
    }

    handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            this.doSearch(this.state.search);
        } else if (e.key === 'Escape') {
            e.preventDefault();
            this.clearSearch();
        }
    }

    mergeVisitor = async e => {
        const { id } = e.target.closest('button').dataset;
        const { animalStore, visitorStore } = this.props.stores;
        const { animal } = animalStore;
        const { searchResult, visitor } = visitorStore;
        const mergeVisitor = searchResult.find(v => v.id === parseInt(id, 10));
        if (!confirm(`Merge current visitor with ${localUtil.displayName(mergeVisitor)}?`)) {
            return;
        }
        // console.log('Changing owner of animal to visitor:')
        // console.log({ visitor })
        await visitorStore.mergeVisitors({
            currentVisitor: visitor.id,
            mergeWith: mergeVisitor.id,
        });

        this.setState({
            search: '',
            visitorMerged: true,
            mergeVisitor,
        });
        // await conventionStore.newParticipant(visitor);
        await this.loadAll();
    }

    render() {
        const {
            searchTitle,
            mergeInfo,
        } = this.props;
        const {
            search,
            visitorMerged,
            mergeVisitor,
        } = this.state;
        const { week, isNew, hideAdmin, drawerLevel } = this.props;
        const { sections } = this.state;
        const { userStore, visitorStore } = this.props.stores;
        const { user, isAdmin } = userStore;
        const { newVisitor, visitor, searchResult } = visitorStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');

        return (<>
            <div class='w-100 d-flex justify-content-center'>
                <div class='w-100' style='max-width: 960px; padding-top: 20px; padding-bottom: 100px;'>
                    {/* <div class='w-100 d-flex flex-column justify-content-start'>
                        <xmp>{JSON.stringify(user.settings, null, 2)}</xmp>
                    </div> */}
                    {/* <xmp>{JSON.stringify(newVisitor, null, 4)}</xmp> */}
                    <div class='font-weight-lighter px-3 box-header mt-0'>
                        {isNew ? <Text id='visitor.new'>New visitor</Text> : <Text id='visitor.edit'>Edit visitor</Text>}
                    </div>
                    <div class='d-flex flex-column justify-content-start pt-3'>
                        <FormFields
                            stores={this.props.stores}
                            sections={sections}
                            holdingStore={visitorStore}
                            objectName={isNew ? 'newVisitor' : 'visitor'}
                            isNew={isNew}
                            drawerLevel={drawerLevel}
                            hideAdmin={hideAdmin}
                        />
                    </div>
                    {isNew ? <div class='w-100 d-flex flex-column justify-content-start mt-3 px-4 py-3 bg-light fixed-bottom' style={`bottom: 0; left: 0;`}>
                        <button type='button' class='btn btn-primary rounded-pill' onClick={this.createVisitor}>
                            {isNew ? <Text id='visitor.create'>Create visitor</Text> : <Text id='visitor.save'>Save visitor</Text>}
                        </button>
                    </div> : <>

                        <div class={`mt-5 mx-4 font-weight-lighter px-3 py-1 text-muted`}>
                            <small class='text-uppercase'>
                                <Text id='dyrejournal.merge-visitors'>Merge visitors</Text>
                            </small>
                        </div>
                        <div class={`${darkmode ? 'bg-darkmode' : 'bg-lightmode'} mx-3 d-flex flex-wrap py-3 px-3 mb-5 box-container`}>
                            {visitorMerged && mergeVisitor ? <>
                                <div class='d-flex flex-wrap justify-content-center w-100'>
                                    <div class='alert alert-success rounded-lg w-100'>
                                        <Text id='dyrejournal.visitor-merged'>Visitor merged with</Text>: {localUtil.displayName(mergeVisitor)}
                                    </div>
                                </div>
                            </> : <>
                                <div class='flex-fill input-group'>
                                    <div class='input-group-prepend'>
                                        <span class='input-group-text rounded-pill-left'>
                                            <i class='fa-regular fa-magnifying-glass'></i>
                                        </span>
                                    </div>
                                    <input
                                        class={`form-control ${!search ? 'rounded-pill-right' : ''}`}
                                        type='text'
                                        value={search}
                                        placeholder={`${searchTitle}`}
                                        onInput={this.searchInput}
                                        onKeyDown={this.handleKeyDown}
                                    />
                                    {search && <div class='input-group-append'>
                                        <button class='btn btn-danger rounded-pill-right' type='button' onClick={this.clearSearch}>
                                            <i class='fa-solid fa-circle-xmark'></i>
                                        </button>
                                    </div>}
                                </div>
                                {searchResult && searchResult.length ? <>
                                    <div class='d-flex flex-wrap justify-content-center'>
                                        {searchResult.map(user => {
                                            const isCurrent = user.id === visitor.id;
                                            return (<>
                                                <button
                                                    disabled={isCurrent}
                                                    class={`btn btn-sm btn-outline-${isCurrent ? 'secondary text-strikethrough' : 'primary'} rounded-pill mx-1 my-1`}
                                                    onClick={this.mergeVisitor}
                                                    data-id={user.id}
                                                >
                                                    <i class={`fa-duotone fa-plus`} /> {localUtil.displayName(user)} {user.cellphone}
                                                </button>
                                            </>);
                                        })}
                                    </div>
                                </> : <></>}
                            </>}
                            <div class='d-flex flex-column'>
                                <small class='text-danger'>
                                    <i class='fa-duotone fa-exclamation-triangle mr-2' style='font-size: 1.5em;' />
                                    <Text id='dyrejournal.merge-customer'>Search for customer you want to merge into this customer. This feature is Experimental! All execution is at your own risk!</Text>
                                </small>
                                <small class='text-muted'>
                                    <i class='fa-duotone fa-info-circle mr-2' style='font-size: 1.5em;' /> {mergeInfo}
                                </small>
                            </div>
                        </div>
                    </>}

                </div>
            </div>
        </>);
    }
}

export default NewVisitor;
