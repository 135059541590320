import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';
import { route } from 'preact-router';
import Markdown from 'preact-markdown';

import localUtil from '../../lib/util';
import fields from '../../lib/fields';

import ViewFields from '../../components/form/viewFields';
import Input from '../../components/form/input';
import DyrejournalSaleSearch from '../../components/dyrejournal/search';

@withText(props => ({
    drugTitle: <Text id='dyrejournal.drug.search-title'>Search for drug</Text>,
}))
@observer
class AddMedicine extends Component {
	constructor(props) {
        super(props);
        this.state = {
            sections: fields.get('journal'),
        };
        this.searchTimer = null;
    }

    loadAll = async (props = this.props, emptyFirst = true) => {
        const { drugStore } = this.props.stores;
        await drugStore.load();
    }

    onClickChooseDrug = e => {
        e.preventDefault();
        e.stopPropagation();
        const { id } = e.target.closest('tr').dataset;
        const { drawerLevel = 1, id: journalId } = this.props;
		const { appState, saleStore, drugStore } = this.props.stores;
        const { newSale } = saleStore;
        const { drugs = [] } = newSale;
        const { drugs: allDrugs } = drugStore;
        const selectedItem = allDrugs.find(d => d.id === parseInt(id, 10));
        if (selectedItem) {
            const { journals = [] } = newSale;
            // Add drug to journal with id journalId
            const journal = journals.find(j => j.id === parseInt(journalId, 10));
console.log('journal', journal);
// saleStore.updateObjectKeyValue('newSale', 'products', [...products, selectedItem]);
            appState.toggleDrawer(false, drawerLevel);
        }
    }

    searchDrugCallback = selectedItem => {
        const { appState, saleStore, productStore } = this.props.stores;
        const { drawerLevel = 1, id: journalId } = this.props;
        const { newSale } = saleStore;
        const { journals = [] } = newSale;
        const journal = journals.find(j => j.id === parseInt(journalId, 10));
console.log('journal', journal);
        // saleStore.updateObjectKeyValue('newSale', 'products', [...products, selectedItem]);
        appState.toggleDrawer(false, drawerLevel);
    }

    componentDidMount() {
        this.loadAll();
    }

    render() {
        const {
            drugTitle,
        } = this.props;
        const { userStore, drugStore } = this.props.stores;
        const { user, isAdmin } = userStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');

        const { drugs } = drugStore;

        return (
            <div class='w-100 d-flex justify-content-center'>
                <div class='w-100 h-100' style='padding-top: 20px; padding-bottom: 100px;'>

                    <div class='w-100 d-flex flex-sm-row flex-column'>
                        <div class='w-100 d-flex flex-column'>
                            <div class='font-weight-lighter px-3 box-header d-flex flex-row justify-content-between'>
                                <Text id='dyrejournal.add-medicine'>Add medicine</Text>
                            </div>
                            <div class={`d-flex flex-column mx-3 px-4 py-3 box-container ${darkmode ? 'bg-darkmode' : 'bg-lightmode'}`}>
                                <DyrejournalSaleSearch
                                    stores={this.props.stores}
                                    key={'drugs'}
                                    title={drugTitle}
                                    holdingStore={drugStore}
                                    searchResultList={'searchResults'}
                                    searchResultsLine={(e, idx, selectedIndex) => {
                                        return(<>
                                            <div class={`d-flex flex-row justify-content-between px-3 py-1 ${idx > 0 ? 'border-top' : ''} ${idx === selectedIndex ? 'bg-secondary' : ''}`}>
                                                <div>
                                                    {e.navnFormStyrke} ({e.pakningsinfoMengde} {e.pakningsinfoEnhetPakningV})

                                                </div>
                                                <div>{e.vat}</div>
                                                <div>{e.price}</div>
                                            </div>
                                        </>);
                                    }}
                                    callback={this.searchDrugCallback}
                                />
                                <div class={`table-responsive ${darkmode ? 'bg-darkmode' : 'bg-lightmode'}`}>
                                    <table class='table table-striped mb-0'>
                                        <thead>
                                            <tr>
                                                <th><Text id='dyrejournal.product.name'>Name</Text></th>
                                                <th class='text-center w-5'><Text id='dyrejournal.product.vat'>Vat</Text></th>
                                                <th class='text-center w-10'><Text id='dyrejournal.product.price'>Price</Text></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(drugs && drugs.length > 0) && drugs.map(drug => {
                                                return (<>
                                                    <tr onClick={this.onClickChooseDrug} data-id={drug.id}>
                                                        <td>
                                                            {drug.navnFormStyrke} ({drug.pakningsinfoMengde} {drug.pakningsinfoEnhetPakningV})
                                                        </td>
                                                        <td class='text-right'>
                                                        </td>
                                                        <td class='text-right'>
                                                        </td>
                                                    </tr>
                                                </>);
                                            })}
                                        </tbody>
                                    </table>
                                </div>

                            </div>
                        </div>
                        {/* <div class='w-100 d-flex flex-column'>
                            <div class='font-weight-lighter px-3 box-header d-flex flex-row justify-content-between'>
                                <Text id='dyrejournal.report.journals'>Journals</Text>
                            </div>
                            <div class={`d-flex flex-column mx-3 px-4 py-3 box-container ${darkmode ? 'bg-darkmode' : 'bg-lightmode'}`}>
                                Report for today's journals
                            </div>
                        </div> */}
                    </div>

                </div>
            </div>
        );
    }
}

export default AddMedicine;
