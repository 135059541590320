import { observable, configure, action, computed } from 'mobx';
import LocalModel from './localModel';

import util from 'preact-util';
import { route } from 'preact-router';
import PubSub, { topics } from '../lib/pubsub';

configure({ enforceActions: 'always' });

class SaleStore extends LocalModel {
    constructor() {
        super('sale', {
            namePlural: 'sales',
            sort: '-saleDate',
            limit: 100,
            api: {
                search: {
                    url: '/api/sales/',
                    params: {
                        limit: 25,
                        sort: '-saleDate',
                    },
                },
                load: {
                    url: '/api/sales/',
                    params: {},
                },
                save: {
                    url: '/api/sales/',
                    params: {},
                },
                delete: {
                    url: '/api/sales/',
                    params: {},
                },
            },
        });
    }

    reminderMethodIcons = {
        email: 'fa-solid fa-envelope',
        sms: 'fa-solid fa-sms',
        call: 'fa-solid fa-phone',
    };

    paymentMethodIcons = {
        cash: 'fa-solid fa-money-bill',
        card: 'fa-solid fa-credit-card',
        invoice: 'fa-solid fa-file-invoice',
        account: 'fa-solid fa-file-invoice-dollar',
        vipps: 'fa-solid fa-mobile',
        creditNote: 'fa-solid fa-dove',
        partial: 'fa-solid fa-exclamation',
        invoiceCredited: 'fa-solid fa-file-invoice',
        saleCredited: 'fa-solid fa-money-bill-transfer',
        priceOffer: 'fa-solid fa-handshake',
        archived: 'fa-solid fa-archive',
        collectiveInvoice: 'fa-solid fa-file-invoice-dollar',
        lost: 'fa-solid fa-times',
        subInvoice: 'fa-solid fa-file-invoice',
    };

    saleStatus = {
        inProgress: 0,
        paid: 1,
        unpaid: 2,
        partial: 3,
        invoice: 4,
        account: 5,
        creditNote: 6,
        invoiceCredited: 7,
        saleCredited: 8,
        priceOffer: 1001,
        archived: 1002,
        collectiveInvoice: 1003,
        lost: 1004,
        subInvoice: 1005,
    };

    saleStatusText = {
        inProgress: { en: 'In progress', no: 'Pågående' },
        paid: { en: 'Paid', no: 'Betalt' },
        unpaid: { en: 'Unpaid', no: 'Ubetalt' },
        partial: { en: 'Partial', no: 'Delvis' },
        invoice: { en: 'Invoice', no: 'Faktura' },
        account: { en: 'Account', no: 'Konto' },
        creditNote: { en: 'Credit note', no: 'Kreditnota' },
        invoiceCredited: { en: 'Invoice credited', no: 'Faktura kreditert' },
        saleCredited: { en: 'Sale credited', no: 'Salg kreditert' },
        priceOffer: { en: 'Price offer', no: 'Pristilbud' },
        archived: { en: 'Archived', no: 'Arkivert' },
        collectiveInvoice: { en: 'Collective invoice', no: 'Samlefaktura' },
        lost: { en: 'Lost', no: 'Tap' },
        subInvoice: { en: 'Sub Invoice', no: 'Underbilag' },
    };

    paymentMethodText = {
        cash: { en: 'Cash', no: 'Kontant' },
        card: { en: 'Card', no: 'Kort' },
        invoice: { en: 'Invoice', no: 'Faktura' },
        account: { en: 'Account', no: 'Konto' },
        vipps: { en: 'Vipps', no: 'Vipps' },
        creditNote: { en: 'Credit note', no: 'Kreditnota' },
        partial: { en: 'Partial', no: 'Delvis' },
        invoiceCredited: { en: 'Invoice credited', no: 'Faktura kreditert' },
        saleCredited: { en: 'Sale credited', no: 'Salg kreditert' },
        priceOffer: { en: 'Price offer', no: 'Pristilbud' },
        archived: { en: 'Archived', no: 'Arkivert' },
        collectiveInvoice: { en: 'Collective invoice', no: 'Samlefaktura' },
        lost: { en: 'Lost', no: 'Tap' },
        subInvoice: { en: 'Sub Invoice', no: 'Underbilag' },
    }

    saleStatusIcons = {
        inProgress: 'fa-solid fa-clock',
        paid: 'fa-solid fa-badge-check',
        unpaid: 'fa-solid fa-times',
        partial: 'fa-solid fa-exclamation',
        invoice: 'fa-solid fa-file-invoice',
        account: 'fa-solid fa-file-invoice-dollar',
        creditNote: 'fa-solid fa-dove',
        invoiceCredited: 'fa-solid fa-file-invoice',
        saleCredited: 'fa-solid fa-money-bill-transfer',
        priceOffer: 'fa-solid fa-handshake',
        archived: 'fa-solid fa-archive',
        collectiveInvoice: 'fa-solid fa-file-invoice-dollar',
        lost: 'fa-solid fa-times',
        subInvoice: 'fa-solid fa-file-invoice',
    };

    saleStatusClass = {
        inProgress: 'secondary',
        paid: 'success',
        unpaid: 'warning',
        partial: 'danger',
        invoice: 'info',
        account: 'info',
        creditNote: 'info',
        invoiceCredited: 'info',
        saleCredited: 'info',
        priceOffer: 'primary',
        archived: 'secondary',
        collectiveInvoice: 'info',
        lost: 'danger',
        subInvoice: 'success',
    };

    checkForUpdatesCounter = 0;

    forms = [
        {
            id: 1,
            name: 'Innleggelse',
            description: 'Skjema til bruk ved innleggelse på klinikken.',
        },
        {
            id: 2,
            name: 'Urinprøve',
            description: 'Skjema til bruk ved urinprøver.',
        },

        {
            id: 101,
            logo: '/assets/logos/hjertedyr.webp',
            name: 'Hjertedyr',
            description: 'Drammen smådyrkrematorium AS.',
            address1: 'Drammen smådyrkrematorium AS',
            address2: 'Gartneriveien 5',
            postalcode: '3058',
            place: 'Solbergelva',
            phone: '923 50 077',
            email: 'info@hjertedyr.no',
            url: 'www.hjertedyr.no',
            orgNum: '923 888 934 MVA',
            info1: `__Viktig info til deg som nettopp har avlevert ditt Hjertedyr.__
Ønsker du ditt dyr tilbake i urne istedenfor enkel eske og dette ikke ble avklart hos veterinæren? Da kan du enkelt bestille denne på vår hjemmeside www.hjertedyr.no. Vi vil anbefale at dere gjør dette snarlig etter besøket hos din veterinær.
`,
            info2: `__Viktig info til veterinær.__
Denne rekvisisjonen skrives ut i 3 eksemplarer. 1 lagres i deres journalsystem, 1 gis til eier og ett eksemplar følger dyret. Gis til sjåfør ved henting av dyr.
`,
        },
        {
            id: 102,
            logo: '/assets/logos/smadyrkrematoriet.jpg',
            name: 'Smådyr Krematoriet AS',
            description: 'Skien Smådyr Krematoriet AS',
            address1: 'Rødmyrlia 10',
            // address2: 'Gartneriveien 5',
            postalcode: '3735',
            place: 'Skien',
            phone: '35 59 43 43',
            email: 'post@smaadyrkrematoriet.no',
            url: 'www.smadyrkrematoriet.no',
            orgNum: '977 330 750 MVA',
            info1: `__Viktig informasjon.__
Utfylt orginal rekvisisjon plasseres i plastlommen på plastsekk.
En kopi oppbevares av eier og en kopi oppbevares av veterinær.
`,
        },
        {
            id: 103,
            logo: '/assets/logos/bergen-smadyrkrematorium.png',
            name: 'Bergen Smådyrkrematorium AS',
            description: 'Bergen Smådyrkrematorium AS',
            address1: 'Håtuftvegen 405',
            // address2: 'Gartneriveien 5',
            postalcode: '5919',
            place: 'Frekhaug',
            phone: '91 71 17 74',
            email: 'post@bskrematorium.no',
            url: 'www.bskrematorium.no',
            orgNum: '999 100 686 MVA',
            info1: `__Viktig informasjon.__
Utfylt orginal rekvisisjon plasseres i plastlommen på plastsekk.
En kopi oppbevares av eier og en kopi oppbevares av veterinær.
`,
        },
    ];

    getPaymentMethodIcon(method) {
        return this.paymentMethodIcons[method] || '';
    }

    getReminderMethodIcon(method) {
        return this.reminderMethodIcons[method] || '';
    }

    getPaymentMethodText(method, languageText = 'no') {
        const text = this.paymentMethodText[method];
        if (text) {
            return text[languageText] || '';
        }
        return method;
    }

    getSaleStatusById(status, languageText) {
        // find status by id
        const statusId = parseInt(status, 10);
        const statusKey = Object.keys(this.saleStatus).find(key => this.saleStatus[key] === statusId);

        if (languageText) {
            const text = this.saleStatusText[statusKey];
            if (text) {
                return text[languageText] || '';
            }
        }

        return statusKey || '';
    }

    getSaleStatus(status) {
        // find status by key
        return this.saleStatus[status] || 0;
    }

    getSaleStatusIcon(status) {
        return this.saleStatusIcons[status] || '';
    }

    getSaleStatusClass(status) {
        return this.saleStatusClass[status] || '';
    }

    hasPrescription(journals) {
        if (!journals || journals.length === 0) return false;
        for (let i = 0, l = journals.length; i < l; i += 1) {
            const journal = journals[i];
            if (journal.prescriptions && journal.prescriptions.length > 0) {
                return journal.prescriptions[0];
            }
        }
    }

    @observable newSale = {
        visitors: [],
        animals: [],
        journals: [],
        products: [],
        comments: [],
    };

    @observable sale = {};

    @observable sales = [];

    @observable notUpdatedFields = [];

    @observable totalSales = 0;

    @observable searchResults = [];

    @observable veterinaries = [];

    @observable employees = [];

    @observable visitorResults = [];

    @observable animalResults = [];

    @observable animalJournals = [];

    @observable visitorAnimals = [];

    @observable visitorInvoices = [];

    @observable visitorSales = [];

    @observable lastVisitors = [];

    @observable currentVisitorSales = [];

    @observable currentPayments = [];

    @observable allPayments = [];

    @observable visitorAllPayments = [];

    @observable productResults = [];

    @observable dailySales = [];

    @observable totalNumberOfSales = 0;

    @observable aggSaleTotal = {};

    @observable aggSaleTotalDiscount = {};

    @observable aggSaleTotalNew = {};

    @observable aggSaleTotalPaymentMethods = [];

    @observable aggSaleTotalPaymentMethodsNew = [];

    @observable aggSaleTotalTax = [];

    @observable aggSalePersons = [];

    @observable aggSalePersonsAccounts = [];

    @observable aggSaleTotalAccounts = [];

    @observable aggSaleWithJournals = {};

    @observable aggSaleNewVisitors = 0;

    @observable aggSaleActiveVisitors = 0;

    @observable reportStatus = null;

    @observable saleIsUpdated = false;

    @observable searchText = null;
    @observable showDeleted = null;
    @observable activeStatus = null;
    @observable currentPage = 1;
    @observable cutoffDate = null;
    @observable cutoffDateEnd = null;
    @observable formatting = true;

    @observable isProductionAnimals = false;

    linePartPrice({ line, field = 'price', format = true, inputQty }) {
        const qty = inputQty || line.qty;
        if (!qty) return 0;

        let aPrice = line[field];
        if (!aPrice) {
            return 0;
        }
        let price = qty * (aPrice || 0);
        if (line.discount) {
            price -= price * (line.discount / 100);
        }
        const taxAmount = price * (line.vat / 100);
        let totalPrice = price + taxAmount;
        if (line.discountAmount) {
            totalPrice -= (line.discountAmount * qty);
        }
        if (format) {
            return util.format(totalPrice, 2, ',', ' ');
        }
        return totalPrice;
    }

    lineTotal(line, format = true, inputQty) {
        const qty = inputQty || line.qty;
        if (!qty) return 0;

        let aPrice = line.price;
        if (line.medicine || line.fee || line.equipment || line.other) {
            aPrice = parseFloat(line.medicine || 0) + parseFloat(line.fee || 0) + parseFloat(line.equipment || 0) + parseFloat(line.other || 0);
        }
        let price = qty * (aPrice || 0);
        if (line.discount) {
            price -= price * (line.discount / 100);
        }
        const taxAmount = price * (line.vat / 100);
        let totalPrice = price + taxAmount;
        if (line.discountAmount) {
            totalPrice -= (line.discountAmount * qty);
        }
        if (format) {
            return util.format(totalPrice, 2, ',', ' ');
        }
        return totalPrice;
    }

    lineDiscount(line, format = true, inputQty) {
        const qty = line.qty || inputQty;
        let totalDiscount = qty * (line.discountAmount || 0);
        if (format) {
            return util.format(totalDiscount, 2, ',', ' ');
        }
        return totalDiscount;
    }

    sumTotal(lines, format = true) {
        if (!lines) return 0;
        let total = 0;
        lines.forEach(line => {
            total += this.lineTotal(line, false);
        });
        // Round the total to 0 decimals
        total = Math.round(total);

        if (format) {
            return util.format(total, 2, ',', ' ');
        }
        return total;
    }

    sumTotalDiscount(lines, format = true) {
        if (!lines) return 0;
        let totalDiscount = 0;
        lines.forEach(line => {
            totalDiscount += this.lineDiscount(line, false);
        });
        // Round the total to 0 decimals
        totalDiscount = Math.round(totalDiscount);

        if (format) {
            return util.format(totalDiscount, 2, ',', ' ');
        }
        return totalDiscount;
    }

    sumTotalAll(sales, format = true) {
        if (!sales) return 0;
        let total = 0;
        sales.forEach(sale => {
            const tot = this.sumTotal(sale.products, false);
// console.log('sumTotalAll.tot', tot);
            total += tot;
        });
        if (format) {
            return util.format(total, 2, ',', ' ');
        }
        return total;
    }

    sumTotalAllDiscount(sales, format = true) {
        if (!sales) return 0;
        let total = 0;
        sales.forEach(sale => {
            const tot = this.sumTotalDiscount(sale.products, false);
// console.log('sumTotalAll.tot', tot);
            total += tot;
        });
        if (format) {
            return util.format(total, 2, ',', ' ');
        }
        return total;
    }

    sumRest(sale, format = true) {
        if (!sale || !sale.partialPayments) return 0;

        const lines = sale.products || [];
        let total = 0;
        lines.forEach(line => {
            total += this.lineTotal(line, false);
        });
        sale.partialPayments.forEach(payment => {
            total -= payment.amount;
        });

        // Round the total to 0 decimals
        total = Math.round(total);

        if (format) {
            return util.format(total, 2, ',', ' ');
        }
        return total;
    }

    sumTotalPayments(paymentList, format = true) {
        if (!paymentList || paymentList.length === 0) return 0;
        let totalPaid = 0;
        paymentList.forEach(payment => {
            totalPaid += payment.amount;
        });

        if (format) {
            return util.format(totalPaid, 2, ',', ' ');
        }
        return totalPaid;
    }

    sumRestPayments(paymentList, total, format = true, hideEmptyOutput = false) {
        if (!paymentList || paymentList.length === 0) return total;
        let totalPaid = 0;
        paymentList.forEach(payment => {
            totalPaid += Math.round(payment.amount);
        });

        if (hideEmptyOutput && total - totalPaid === 0) {
            return '';
        }
        if (format) {
            return util.format(total -totalPaid, 2, ',', ' ');
        }
// console.log('totalPaid', totalPaid);
// console.log('total', total);
// console.log('total - totalPaid', total - totalPaid);
        return total - totalPaid;
    }

    sumRestAll(sales, format = true) {
        if (!sales) return 0;
        let total = 0;
        sales.filter(s => s.status === 0 || s.status === 2 || s.status === 3).forEach(sale => {
            const rest = this.sumRest(sale, false);
            if (rest > 0) {
                total += rest;
            } else {
                const tot = this.sumTotal(sale.products, false);
                if (tot > 0) {
                    total += tot;
                }
            }
        });
        if (format) {
            return util.format(total, 2, ',', ' ');
        }
        return total;
    }

    sumVat(lines) {
        if (!lines) return 0;
        let totalTax = 0;
        lines.forEach(product => {
            const qty = product.qty;
            let aPrice = product.price;
            if (product.medicine || product.fee || product.equipment || product.other) {
                aPrice = (product.medicine || 0) + (product.fee || 0) + (product.equipment || 0) + (product.other || 0);
            }
            let productPrice = qty * aPrice;
            if (product.discount) {
                productPrice -= productPrice * (product.discount / 100);
            }
            if (product.discountAmount > 0) {
                productPrice -= (product.discountAmount * qty);
            }
            const taxAmount = productPrice * (product.vat / 100);
            totalTax += taxAmount;
        });
        return util.format(totalTax, 2, ',', ' ');
    }

    @computed
    get saleTotal() {
        if (!this.newSale || !this.newSale.products) return 0;
        let total = 0;
        this.newSale.products.forEach(product => {
            const qty = product.qty;
            let aPrice = product.price;
            if (product.medicine || product.fee || product.equipment || product.other) {
                aPrice = (product.medicine || 0) + (product.fee || 0) + (product.equipment || 0) + (product.other || 0);
            }
            let productPrice = qty * aPrice;
            if (product.discount) {
                productPrice -= productPrice * (product.discount / 100);
            }
            const taxAmount = productPrice * (product.vat / 100);
            let totalPrice = productPrice + taxAmount;
            if (product.discountAmount > 0) {
                totalPrice -= (product.discountAmount * qty);
            }
            total += totalPrice;
            // substract discount for each line. Ie 40% discount on product price.
        });

        // Round the total to 0 decimals
        total = Math.round(total);
        return total;
    }

    @computed
    get saleTax() {
        // Calculate tax part on all products
        if (!this.newSale || !this.newSale.products) return 0;
        let totalTax = 0;
        this.newSale.products.forEach(product => {
            const qty = product.qty;
            let aPrice = product.price;
            if (product.medicine || product.fee || product.equipment || product.other) {
                aPrice = (product.medicine || 0) + (product.fee || 0) + (product.equipment || 0) + (product.other || 0);
            }
            let productPrice = qty * aPrice;
            if (product.discount) {
                productPrice -= productPrice * (product.discount / 100);
            }
            let taxAmount = productPrice * (product.vat / 100);
            // console.log('taxAmount', taxAmount);
            if (product.discountAmount) {
                // Discount has tax included and we need to get the tax part of the discount
                const discountTax = (product.discountAmount * qty) / 1.25 * 0.25;
                // console.log('discountTax', discountTax);
                taxAmount -= discountTax;
            }
            // console.log('taxAmount for line', taxAmount);
// console.log('product.discountAmount', product.discountAmount);
// console.log('taxAmount', taxAmount);
            totalTax += taxAmount;
        });
        return util.format(totalTax, 2, ',', ' ');
    }

    calculateTax(totalWithoutTax, vat = 25) {
        const taxAmount = totalWithoutTax * (vat / 100);
        return taxAmount;
    }

    makeNewSaleTitle() {
        // Make title for sale
        const { visitors, animals = [], saleDate = new Date() } = this.newSale;
        let newSaleTitle = `Sale ${util.formatDate(saleDate, { hour12: false, hour: '2-digit', minute: '2-digit', locale: 'nb-NO' })}`;
        if (visitors && visitors.length >= 0 && visitors[0]) {
            const visitor = visitors[0];
            newSaleTitle = `${visitor.firstname} ${visitor.lastname}`;
        }
        if (animals && animals.length >= 0 && animals[0]) {
            const animal = animals[0];
            newSaleTitle = `${newSaleTitle} - ${animal.name} ${animal.birth ? `(${util.age(animal.birth)})` : ''}`;
        }
        this.updateObjectKeyValue('newSale', 'title', newSaleTitle);
    }

    prepareSale(props = {}) {
        const { newSale } = this;
        const { visitors, animals, journals, products, productAdded, productRemoved } = newSale;
        let sale = {
            id: newSale.id,
        };
        if (props.fieldsToSave) {
            props.fieldsToSave.forEach(field => {
                if (['visitors', 'animals', 'journals', 'products'].indexOf(field) > -1) {
                    sale[`${field}-overwrite`] = newSale[field];
                } else {
                    sale[field] = newSale[field];
                }
            });
        } else {
            sale = {
                ...newSale,
                ['visitors-overwrite']: visitors,
                ['animals-overwrite']: animals,
                ['journals-overwrite']: journals,
                ['products-overwrite']: products,
            };
        }
        if (props.calledFrom) {
            sale.calledFrom = props.calledFrom;
        }
        if (props.event) {
            sale.event = {
                isTrusted: props.event?.isTrusted,
                clientX: props.event?.clientX,
                clientY: props.event?.clientY,
                pageX: props.event?.pageX,
                pageY: props.event?.pageY,
                altKey: props.event?.altKey,
                ctrlKey: props.event?.ctrlKey,
            };
        }
        // if (window) {
        //     sale.window = {
        //         screenWidth: window.screen.width,
        //         screenHeight: window.screen.height,
        //         innerWidth: window.innerWidth,
        //         innerHeight: window.innerHeight,
        //     };
        // }
        delete sale.visitors;
        delete sale.animals;
        delete sale.journals;
        delete sale.products;
        delete sale.saleDate;
        delete sale.saleNumber;

        if (!productAdded && !productRemoved) {
            // If no products added or removed, then use the existing products
            delete sale['products-overwrite'];
        }

        // fieldsToSkip: ['paymentMethod', 'paymentAmount', 'status', 'paidDate']
        if (props.fieldsToSkip) {
            props.fieldsToSkip.forEach(field => {
                delete sale[field];
            });
        }

        return sale;
    }

    // getVeterinary(id) {
    //     return this.veterinaries.find(vet => vet.id === id);
    // }

    getEmployee(id) {
        return this.employees.find(emp => emp.id === id);
    }

    // async loadVeterinaries() {
    //     const response = await util.fetchApi(`/api/users/veterinaries/`, { method: 'GET' }, {});
    //     switch (response.status) {
    //         case 200:
    //             this.updateKeyValue('veterinaries', response.data);
    //             return response;

    //     }
    // }

    async loadEmployees() {
        const response = await util.fetchApi(`/api/users/employees/`, { method: 'GET' }, {});
        switch (response.status) {
            case 200:
                this.updateKeyValue('employees', response.data);
                return response;
        }
    }

    /**
     * Saves a sale.
     *
     * @param {Object} props - The properties for the sale.
     * @param {Array} props.visitors - The list of visitors for the sale.
     * @param {Array} props.animals - The list of animals for the sale.
     * @param {Array} props.journals - The list of journals for the sale.
     * @param {Array} props.products - The list of products for the sale.
     * @param {string} props.calledFrom - The source of the sale.
     * @return {boolean} Returns true if the sale is saved successfully, otherwise false.
     */
    async saveSale(props = {}) {
        const { newSale } = this;
        const hasVisitors = newSale.visitors && newSale.visitors.length > 0;
        const hasAnimals = newSale.animals && newSale.animals.length > 0;
        const hasJournal = newSale.journals && newSale.journals.length > 0;
        const hasProducts = newSale.products && newSale.products.length > 0;

        if (!hasVisitors) {
            return false; // Do NOT save empty sales
        }
        if (!(hasProducts || hasJournal)) {
            return false; // Do NOT save empty sales
        }

        // TODO: Need to add latest version of journal to sale.
        if (newSale.id) {
            // this.makeNewSaleTitle();
            const preparedSale = this.prepareSale(props);
            const response = await this.save(preparedSale);
            const sale = response?.data?.sale;
            // console.log(sale)
            this.updateObjectKeyValue('newSale', 'updatedDate', sale?.updatedDate);
            this.updateObjectKeyValue('newSale', 'saleNumber', sale?.saleNumber);
            // this.updateObjectKeyValue('newSale', 'paidDate', sale?.paidDate);
            // this.updateObjectKeyValue('newSale', 'products', sale?.products);
            this.updateKeyValue('notUpdatedFields', response?.included?.notUpdatedFields);
        } else {
            this.makeNewSaleTitle();
            const response = await this.insert({
                ...newSale,
                calledFrom: props.calledFrom,
            });
            const sale = response?.data?.sale;
            // Update current newSale object
            this.updateObjectKeyValue('newSale', 'id', sale?.id);
            this.updateObjectKeyValue('newSale', 'saleNumber', sale?.saleNumber);
            this.updateObjectKeyValue('newSale', 'updatedDate', sale?.updatedDate);
            this.updateObjectKeyValue('newSale', 'saleDate', sale?.saleDate);
            this.updateObjectKeyValue('newSale', 'user', sale?.user);
        }
        this.updateKeyValue('saleIsUpdated', false);
        this.updateObjectKeyValue('newSale', 'productAdded', false);
        this.updateObjectKeyValue('newSale', 'productRemoved', false);
        return true;
    }

    async deleteSale(saleId) {
        const response = await util.fetchApi(`/api/sales/${saleId}`, { method: 'DELETE' }, {});
        switch (response.status) {
            case 202:
                return response;
            default:
                return 'Error';
        }
    }

    /**
     * Creates a new sale with the given properties.
     *
     * @param {Object} props - The properties for the new sale.
     * @param {Array} [props.visitors=[]] - The visitors for the sale.
     * @param {Array} [props.animals=[]] - The animals for the sale.
     * @param {Object} props.user - The user for the sale.
     * @param {boolean} [props.skipSearchResults=false] - Whether to skip updating the search results.
     * @param {boolean} [props.skipVeterinaries=false] - Whether to skip updating the veterinaries.
     * @param {boolean} [props.skipVisitorResults=false] - Whether to skip updating the visitor results.
     * @param {boolean} [props.skipAnimalResults=false] - Whether to skip updating the animal results.
     * @param {boolean} [props.skipAnimalJournals=false] - Whether to skip updating the animal journals.
     * @param {boolean} [props.skipVisitorAnimals=false] - Whether to skip updating the visitor animals.
     * @param {boolean} [props.skipVisitorInvoices=false] - Whether to skip updating the visitor invoices.
     * @param {boolean} [props.skipProductResults=false] - Whether to skip updating the product results.
     * @param {boolean} [props.skipVisitorSales=false] - Whether to skip updating the visitor sales.
     * @param {boolean} [props.skipCurrentVisitorSales=false] - Whether to skip updating the current visitor sales.
     * @param {boolean} [props.skipCurrentPayments=false] - Whether to skip updating the current payments.
     */
    createNewSale(props = {}) {
        this.updateKeyValue('newSale', {
            id: null,
            visitors: props.visitors || [],
            animals: props.animals || [],
            journals: [],
            products: [],
            comments: [],
            user: props.user,
            productAdded: false,
            productRemoved: false,
        });
        if (!props.skipSearchResults) this.updateKeyValue('searchResults', []);
        if (!props.skipVeterinaries) this.updateKeyValue('veterinaries', []);
        // if (!props.skipEmployees) this.updateKeyValue('employees', []);
        if (!props.skipVisitorResults) this.updateKeyValue('visitorResults', []);
        if (!props.skipAnimalResults) this.updateKeyValue('animalResults', []);
        if (!props.skipAnimalJournals) this.updateKeyValue('animalJournals', []);
        if (!props.skipVisitorAnimals) this.updateKeyValue('visitorAnimals', []);
        if (!props.skipVisitorInvoices) this.updateKeyValue('visitorInvoices', []);
        if (!props.skipProductResults) this.updateKeyValue('productResults', []);
        if (!props.skipVisitorSales) this.updateKeyValue('visitorSales', []);
        if (!props.skipCurrentVisitorSales) this.updateKeyValue('currentVisitorSales', []);
        if (!props.skipCurrentPayments) this.updateKeyValue('currentPayments', []);
        this.updateKeyValue('notUpdatedFields', []);
        this.updateKeyValue('saleIsUpdated', false);
        this.getLastVisitors();
    }

    async getLastVisitors() {
        const response = await util.fetchApi(`/api/sales/vistors/last`, { method: 'GET' }, {});
        switch (response.status) {
            case 200:
                this.updateKeyValue('lastVisitors', response.data);
                return response;
        }
    }

    async emailReceipt(sale) {
        const response = await util.fetchApi(`/api/sales/${sale.id}/email/receipt`, { method: 'GET' }, {});
        switch (response.status) {
            case 200:
                return response;
            default:
                return 'Error';
        }
    }

    async emailReminder({ sale, to, subject, body }) {
        const response = await util.fetchApi(`/api/sales/${sale.id}/email/reminder`, { method: 'GET' }, { to, subject, body });
        switch (response.status) {
            case 200:
                return response;
            default:
                return 'Error';
        }
    }

    async emailPriceoffer({ sale, to, subject, body }) {
        const response = await util.fetchApi(`/api/sales/${sale.id}/email/priceoffer`, { method: 'GET' }, { to, subject, body });
        switch (response.status) {
            case 200:
                return response;
            default:
                return 'Error';
        }
    }

    async loadDailyReport(reportDate = '') {
        this.updateKeyValue('reportStatus', null);
        const response = await util.fetchApi(`/api/sales/report?reportDate=${reportDate}`, { method: 'GET' }, {});
        switch (response.status) {
            case 200:
                this.updateKeyValue('dailySales', response.data);
                this.updateKeyValue('totalNumberOfSales', response.included?.totalNumberOfSales);
                this.updateKeyValue('aggSaleTotal', response.included?.saleTotal);
                this.updateKeyValue('aggSaleTotalDiscount', response.included?.saleTotalDiscount);
                this.updateKeyValue('aggSaleTotalNew', response.included?.saleTotalNew);
                this.updateKeyValue('aggSaleTotalPaymentMethods', response.included?.saleTotalPaymentMethods);
                this.updateKeyValue('aggSaleTotalPaymentMethodsNew', response.included?.saleTotalPaymentMethodsNew);
                this.updateKeyValue('aggSaleTotalTax', response.included?.saleTotalTax);
                this.updateKeyValue('aggSalePersons', response.included?.salePersons);
                this.updateKeyValue('aggSalePersonsAccounts', response.included?.salePersonsAccounts);
                this.updateKeyValue('aggSaleTotalAccounts', response.included?.saleTotalAccounts);
                this.updateKeyValue('aggSaleWithJournals', response.included?.salesWithJournals);
                this.updateKeyValue('aggSaleNewVisitors', response.included?.saleNewVisitors);
                this.updateKeyValue('aggSaleActiveVisitors', response.included?.saleActiveVisitors);
                return response;
            case 500:
                this.updateKeyValue('reportStatus', 'Error loading reports.');
            default:
                this.updateKeyValue('reportStatus', 'Something went wrong. Please try again.');
        }
    }

    async loadMonthlyReport(reportDate = new Date(), onlyFromDate = false) {
        this.updateKeyValue('reportStatus', null);
        const date = new Date(reportDate);
        let startOfMonth;
        if (onlyFromDate) {
            startOfMonth = util.isoDate(date, 1);
        } else {
            startOfMonth = util.isoDate(new Date(date.getFullYear(), date.getMonth(), 1));
        }
        const endOfMonth = util.isoDate(new Date(date.getFullYear(), date.getMonth() + 1, 0));
        const response = await util.fetchApi(`/api/sales/report/month?startDate=${startOfMonth}&endDate=${endOfMonth}`, { method: 'GET' }, {});
        switch (response.status) {
            case 200:
                this.updateKeyValue('dailySales', response.data);
                this.updateKeyValue('totalNumberOfSales', response.included?.totalNumberOfSales);
                this.updateKeyValue('aggSaleTotal', response.included?.saleTotal);
                this.updateKeyValue('aggSaleTotalDiscount', response.included?.saleTotalDiscount);
                this.updateKeyValue('aggSaleTotalNew', response.included?.saleTotalNew);
                this.updateKeyValue('aggSaleTotalPaymentMethods', response.included?.saleTotalPaymentMethods);
                this.updateKeyValue('aggSaleTotalPaymentMethodsNew', response.included?.saleTotalPaymentMethodsNew);
                this.updateKeyValue('aggSaleTotalTax', response.included?.saleTotalTax);
                this.updateKeyValue('aggSalePersons', response.included?.salePersons);
                this.updateKeyValue('aggSalePersonsAccounts', response.included?.salePersonsAccounts);
                this.updateKeyValue('aggSaleTotalAccounts', response.included?.saleTotalAccounts);
                this.updateKeyValue('aggSaleWithJournals', response.included?.salesWithJournals);
                this.updateKeyValue('aggSaleNewVisitors', response.included?.saleNewVisitors);
                this.updateKeyValue('aggSaleActiveVisitors', response.included?.saleActiveVisitors);
                return response;
            case 500:
                this.updateKeyValue('reportStatus', 'Error loading reports.');
            default:
                this.updateKeyValue('reportStatus', 'Something went wrong. Please try again.');

        }
    }

    async loadYearlyReport(reportDate = new Date(), onlyFromDate = false) {
        this.updateKeyValue('reportStatus', null);
        const date = new Date(reportDate);
        let startOfYear;
        if (onlyFromDate) {
            startOfYear = util.isoDate(date, 1);
        } else {
            startOfYear = util.isoDate(new Date(date.getFullYear(), 0, 1));
        }
        const endOfYear = util.isoDate(new Date(date.getFullYear(), 12, 0));
        const response = await util.fetchApi(`/api/sales/report/year?startDate=${startOfYear}&endDate=${endOfYear}`, { method: 'GET' }, {});
        switch (response.status) {
            case 200:
                this.updateKeyValue('dailySales', response.data);
                this.updateKeyValue('totalNumberOfSales', response.included?.totalNumberOfSales);
                this.updateKeyValue('aggSaleTotal', response.included?.saleTotal);
                this.updateKeyValue('aggSaleTotalDiscount', response.included?.saleTotalDiscount);
                this.updateKeyValue('aggSaleTotalNew', response.included?.saleTotalNew);
                this.updateKeyValue('aggSaleTotalPaymentMethods', response.included?.saleTotalPaymentMethods);
                this.updateKeyValue('aggSaleTotalPaymentMethodsNew', response.included?.saleTotalPaymentMethodsNew);
                this.updateKeyValue('aggSaleTotalTax', response.included?.saleTotalTax);
                this.updateKeyValue('aggSalePersons', response.included?.salePersons);
                this.updateKeyValue('aggSalePersonsAccounts', response.included?.salePersonsAccounts);
                this.updateKeyValue('aggSaleTotalAccounts', response.included?.saleTotalAccounts);
                this.updateKeyValue('aggSaleWithJournals', response.included?.salesWithJournals);
                this.updateKeyValue('aggSaleNewVisitors', response.included?.saleNewVisitors);
                this.updateKeyValue('aggSaleActiveVisitors', response.included?.saleActiveVisitors);
                return response;
            case 500:
                this.updateKeyValue('reportStatus', 'Error loading reports.');
            default:
                this.updateKeyValue('reportStatus', 'Something went wrong. Please try again.');
        }
    }

    getPaymentMethodTotal(method) {
        const { aggSaleTotalPaymentMethods } = this;
        const paymentMethod = aggSaleTotalPaymentMethods.find(item => item.paymentMethod === method);
        if (paymentMethod) return paymentMethod.price;// * (1 + paymentMethod.vat / 100);
        return 0;
    }

    getPaymentMethodTotalNew(method) {
        const { aggSaleTotalPaymentMethodsNew } = this;
        const paymentMethod = aggSaleTotalPaymentMethodsNew.find(item => item.paymentMethod === method);
        if (paymentMethod) return paymentMethod.price;// * (1 + paymentMethod.vat / 100);
        return 0;
    }

    checkForUpdates(clearCounter = true) {
        if (clearCounter) {
            this.checkForUpdatesCounter = 0;
        }
        const { newSale } = this;
        // console.log('checkForUpdates', newSale.id);
        clearTimeout(this.updateTimer);
        this.updateTimer = setTimeout(() => {
            this.doCheckForUpdates();
        }, 60000);
    }

    async doCheckForUpdates() {
        const { newSale } = this;
        if (this.checkForUpdatesCounter > 60) {
            return false;
        }
        if (newSale.id) {
            const response = await this.load({
                query: {
                    id: newSale.id,
                },
                skipUpdate: true,
            });
            const lastVersion = response[0];
            if (lastVersion.updatedDate > newSale.updatedDate) {
                this.updateKeyValue('saleIsUpdated', true);
                // console.log('---> sale has been updated!');
                // console.log('newSale', newSale.updatedDate);
                // console.log('lastVersion', lastVersion.updatedDate);
            } else {
                this.updateKeyValue('saleIsUpdated', false);
            }
            this.checkForUpdatesCounter += 1;
        } else {
            this.checkForUpdatesCounter = 0;
        }
        this.checkForUpdates(false);
    }
}

const store = new SaleStore();
export default store;
