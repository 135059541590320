import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';
import Markdown from 'preact-markdown';
import { route } from 'preact-router';

import fields from '../../lib/fields';

import ImageScroller from '../../components/imagescroller';
import GuiInfoBox from '../../components/gui/infoBox';
import PaymentVippsGeneric from '../../components/payment/vippsGeneric';
import PaymentBank from '../../components/payment/bank';
import PaymentPaypalGeneric from '../../components/payment/paypalGeneric';
import PaymentReceipt from '../../components/payment/receipt';

import localUtil from '../../lib/util';

@withText(props => ({
    amountText: <Text id='race-class.signup.amount-title'>Amount to pay</Text>,
}))
@observer
class RaceClassContestantPayGeneric extends Component {
  	constructor(props) {
        super(props);
        this.state = {
            sessionid: new Date().getTime(),
            paymentMethods: [],
            paymentInProgress: false,
        };
        this.mainContainer = null;
    }

    loadAll = async (props = this.props) => {
        const { raceStore, customerStore } = this.props.stores;
        const { race, customers } = raceStore;
        const currentCustomer = customers?.find(c => c.id === race.customer);

        const paymentMethods = [];
        if (currentCustomer?.featureFlags?.hasVippsPayment) {
            paymentMethods.push('vipps');
        }
        if (currentCustomer?.featureFlags?.hasBankPayment) {
            paymentMethods.push('bank');
        }
        if (currentCustomer?.featureFlags?.hasPaypalPayment) {
            paymentMethods.push('paypal');
        }
        this.setState({ paymentMethods });
    }

    choosePaymentMethod = async (e) => {
        const { method } = e.target.closest('button').dataset;
        const { raceClassContestantStore } = this.props.stores;
        const { raceClassContestant } = raceClassContestantStore;
        await raceClassContestantStore.saveField(raceClassContestant.id, 'paymentMethod', method, true);
    }

    paymentCallback = async (data) => {
        // console.log('paymentCallback', data);
        this.setState({ paymentInProgress: true });
    }

    paymentDoneCallback = async ({ currency, amount, title, type, paymentUuidv4, race, raceClassContestant }) => {
        // callbackPaymentDone({ currency, amount, title, type, paymentUuidv4: uuidv4, race, raceClassContestant });
        // console.log({ currency, amount, title, type, paymentUuidv4, race, raceClassContestant });
        const { raceClassContestantStore } = this.props.stores;
        const field = `paid${util.ucfirst(type)}`;
        raceClassContestantStore.save({
            id: raceClassContestant,
            [field]: new Date(),
        });
        // console.log('paymentDoneCallback', data);
        this.setState({ paymentInProgress: false });
    }

    payment = () => {
        const {
            paymentType,
            amount,
            paymentTitle,
        } = this.props;
        const { paymentMethods = [] } = this.state;
        const { raceClassContestantStore, raceStore } = this.props.stores;
        const { race } = raceStore;
        const { raceClassContestant } = raceClassContestantStore;
        const { customer } = race;
        let paymentMethod = raceClassContestant.paymentMethod || paymentMethods[0];
        if (!paymentMethods.includes(paymentMethod)) {
            paymentMethod = paymentMethods[0];
        }
        switch (paymentMethod) {
            case 'vipps':
                return <PaymentVippsGeneric
                    stores={this.props.stores}
                    title={paymentTitle}
                    type={paymentType}
                    amount={amount}
                    customer={customer}
                    race={raceClassContestant.race}
                    raceClassContestant={raceClassContestant.id}
                    callback={this.paymentCallback}
                    callbackPaymentDone={this.paymentDoneCallback}
                />;
                break;
            case 'bank':
                return <PaymentBank
                    stores={this.props.stores}
                    title={paymentTitle}
                    type={paymentType}
                    amount={amount}
                    customer={customer}
                    race={raceClassContestant.race}
                    raceClassContestant={raceClassContestant.id}
                />;
                break;
            case 'paypal':
                return <PaymentPaypalGeneric
                    stores={this.props.stores}
                    title={paymentTitle}
                    type={paymentType}
                    amount={amount}
                    customer={customer}
                    race={raceClassContestant.race}
                    raceClassContestant={raceClassContestant.id}
                    callback={this.paymentCallback}
                    callbackPaymentDone={this.paymentDoneCallback}
                />;
                break;
            default:
                return <PaymentVippsGeneric
                    stores={this.props.stores}
                    title={paymentTitle}
                    type={paymentType}
                    amount={amount}
                    customer={customer}
                    race={raceClassContestant.race}
                    raceClassContestant={raceClassContestant.id}
                    callback={this.paymentCallback}
                    callbackPaymentDone={this.paymentDoneCallback}
                />;
                break;
        }
    }

    receipt = () => {
        const {
            paymentType,
            amount,
            paymentTitle,
        } = this.props;
        const { paymentMethods = [] } = this.state;
        const { raceClassContestantStore } = this.props.stores;
        const { raceClassContestant } = raceClassContestantStore;
        let paymentMethod = raceClassContestant.paymentMethod || paymentMethods[0];
        if (!paymentMethods.includes(paymentMethod)) {
            paymentMethod = paymentMethods[0];
        }

        return <PaymentReceipt
            stores={this.props.stores}
            title={paymentTitle}
            type={paymentType}
            amount={amount}
            race={raceClassContestant.race}
            raceClassContestant={raceClassContestant.id}
        />;
    }

    componentDidMount() {
        this.loadAll();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.page !== this.props.page || nextProps.artid !== this.props.artid) {
            this.loadAll(nextProps);
        }
    }

    render() {
        const {
            paymentInProgress,
            paymentMethods,
        } = this.state;
        const {
            amountText,
        } = this.props;
        const {
            paymentTitle,
            paymentType,
            amount,
        } = this.props;
        const { appState, userStore, raceClassContestantStore, paymentStore } = this.props.stores;
        const { user = {}, isAdmin, isTester, isVeterinary, language } = userStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');

        const { raceClassContestant } = raceClassContestantStore;
        const field = `paid${util.ucfirst(paymentType)}`;

        return (<>
            <div class='w-100 d-flex justify-content-center'>
                <div class='w-100 h-100' style='max-width: 960px; padding-top: 20px; padding-bottom: 100px;'>

                    <div class='font-weight-lighter px-3 box-header mt-0'>
                        {paymentTitle}
                    </div>

                    <div class='w-100 d-flex flex-column'>
                        <div class={`d-flex flex-column mx-3 rounded-lg ${darkmode ? 'bg-darkmode' : 'bg-lightmode'} px-4 py-3`}>
                            {raceClassContestant[field] ? <>
                                {this.receipt()}
                            </> : <>
                                {this.payment()}
                            </>}
                        </div>
                    </div>

                    {!paymentInProgress && !raceClassContestant[field] && <>
                        <div class='font-weight-lighter px-3 box-header mt-4'>
                            <Text id='race-class.payment-methods'>Payment methods</Text>
                        </div>
                        <div class='w-100 d-flex flex-column'>
                            <div class={`d-flex flex-column mx-3 rounded-lg ${darkmode ? 'bg-darkmode' : 'bg-lightmode'} px-4 py-3`}>
                                <div class='w-100 d-flex flex-row justify-content-center'>
                                    {paymentMethods.map(method => {
                                        return (<>
                                            <button
                                                type='button'
                                                data-method={method}
                                                class={`btn btn-${method === raceClassContestant.paymentMethod ? 'primary' : 'outline-secondary'} rounded-pill mr-2 mt-2`}
                                                onClick={this.choosePaymentMethod}
                                            >
                                                {method}
                                            </button>
                                        </>);
                                    })}
                                </div>

                            </div>
                        </div>
                    </>}

                </div>
            </div>
        </>);
    }
}

export default RaceClassContestantPayGeneric;
