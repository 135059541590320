import { h, Component, Fragment } from 'preact';
import { observer } from 'mobx-preact';
import { Text, Localizer } from 'preact-i18n';
import linkState from 'linkstate';
import util from 'preact-util';
import { route } from 'preact-router';

import BugReport from '../../components/bugReport/';
import Drawer from '../../components/drawer/';
import DrawerRight from '../../components/drawer/right';
import Settings from '../../components/settings/';
import Menu from '../../components/menu';
import SendEmail from '../../components/actions/sendEmail';
import SendSms from '../../components/actions/sendSms';
import AddToCalendar from '../../components/actions/addToCalendar';

import Calendar from '../../routes/calendar';

import AdminNewUser from '../../components/admin/newUser';
import AdminNewNews from '../../components/admin/newNews';
import AdminNewCustomer from '../../components/admin/newCustomer';
import AdminNewInstallation from '../../components/admin/newInstallation';
import AdminNewPart from '../../components/admin/newPart';
import AdminNewInspection from '../../components/admin/newInspection';
import AdminNewLoan from '../../components/admin/newLoan';
import AdminNewJournal from '../../components/admin/newJournal';
import AdminNewPrescription from '../../components/admin/newPrescription';
import AdminNewDrug from '../../components/admin/newDrug';
import AdminNewAnimal from '../../components/admin/newAnimal';
import AdminNewAnimalBreed from '../../components/admin/newAnimalBreed';
import AdminNewAnimalSpecie from '../../components/admin/newAnimalSpecie';
import AdminNewVisitor from '../../components/admin/newVisitor';
import AdminNewSale from '../../components/admin/newSale';
import AdminNewSalePayment from '../../components/admin/newSalePayment';
import AdminNewSaleReport from '../../components/admin/newSaleReport';
import AdminNewProduct from '../../components/admin/newProduct';
import AdminNewDiagnose from '../../components/admin/newDiagnose';
import AdminNewFile from '../../components/admin/newFile';
import AdminNewMedia from '../../components/admin/newMedia';
import AdminNewArticle from '../../components/admin/newArticle';
import AdminNewRace from '../../components/admin/newRace';
import AdminNewRaceClass from '../../components/admin/newRaceClass';
import AdminNewRaceClassContestant from '../../components/admin/newRaceClassContestant';
import AdminNewRaceResult from '../../components/admin/newRaceResult';
import AdminNewTimeTracker from '../../components/admin/newTimeTracker';
import AdminNewPayment from '../../components/admin/newPayment';

import AdminNewContact from '../../components/admin/newContact';
import AdminNewCalendar from '../../components/admin/newCalendar';
import AdminNewCalendarEvent from '../../components/admin/newCalendarEvent';
import AdminNewGpsDevice from '../../components/admin/newGpsDevice';
import AdminNewStream from '../../components/admin/newStream';
import AdminNewStreamDevice from '../../components/admin/newStreamDevice';
import AdminNewTextTemplate from '../../components/admin/newTextTemplate';
import AdminNewSms from '../../components/admin/newSms';

import AdminNewConvention from '../../components/admin/newConvention';
import AdminNewConventionResult from '../../components/admin/newConventionResult';

import AdminViewGpsPosition from '../../components/admin/viewGpsPosition';

import AddArrayObject from '../../components/form/addArrayObject';
import EditArrayObject from '../../components/form/editArrayObject';
import AddArrayElement from '../../components/form/addArrayElement';
import EditField from '../../components/form/editField';

import ImageView from '../../components/imagescroller/view';
import ImageFullscreen from '../../components/gui/imageFullscreen';

import Help from '../../components/help/';

import NewsView from '../../components/news/view';
import NewsMenu from '../../components/news/menu';
import NewsEdit from '../../components/news/edit';
import NewsAdminList from '../../components/news/listAdmin';

import CustomerView from '../../components/customer/view';
import CustomerEdit from '../../components/customer/edit';
import CustomerUserEdit from '../../components/customer/editUser';

import PartView from '../../components/part/view';
import PartEdit from '../../components/part/edit';
import PartSort from '../../components/part/sort';
import PartAddUsageData from '../../components/part/addUsageData';

import MediaEdit from '../../components/media/edit';
import MediaView from '../../components/media/view';

import ArticleEdit from '../../components/article/edit';
import ArticleView from '../../components/article/view';
import ArticleList from '../../components/article/list';
import ArticleAdminList from '../../components/article/listAdmin';

import ContactEdit from '../../components/contact/edit';
import ContactList from '../../components/contact/list';
import ContactView from '../../components/contact/view';

import FileEdit from '../../components/file/edit';
import FileList from '../../components/file/list';
import FileListTable from '../../components/file/listTable';
import FileView from '../../components/file/view';

import TimeTrackerEdit from '../../components/timetracker/edit';
import TimeTrackerView from '../../components/timetracker/view';

import CalendarEventEdit from '../../components/calendarEvent/edit';
import CalendarEventView from '../../components/calendarEvent/view';

import CalendarEdit from '../../components/calendar/edit';
import CalendarView from '../../components/calendar/view';

import ConventionEdit from '../../components/convention/edit';
import ConventionEditParticipant from '../../components/convention/editParticipant';
import ConventionEditStage from '../../components/convention/editStage';
import ConventionView from '../../components/convention/view';
import ConventionResultEditImage from '../../components/conventionresult/editImage';
import ConventionResultViewImage from '../../components/conventionresult/viewImage';

import CalendarAvailabilityEdit from '../../components/calendarAvailability/edit';
import CalendarAvailabilityView from '../../components/calendarAvailability/view';

import TextTemplateEdit from '../../components/textTemplate/edit';
import TextTemplateView from '../../components/textTemplate/view';

import RaceClassContestantAdminView from '../../components/raceClassContestant/admin/view';
import RaceClassContestantAdminList from '../../components/raceClassContestant/admin/list';

import RaceClassContestantView from '../../components/raceClassContestant/view';
import RaceClassCheckpointView from '../../components/raceClass/viewCheckpoint';
import RaceClassPaySignupFee from '../../components/raceClassContestant/paySignupFee';
import RaceClassPaySignup from '../../components/raceClassContestant/paySignup';
import RaceClassPayRest from '../../components/raceClassContestant/payRest';
import RaceClassPayGeneric from '../../components/raceClassContestant/payGeneric';
import RaceViewMap from '../../components/race/viewMap';

import RaceSignup from '../../components/race/signup';

import PartAddToInstallation from '../../components/part/addToInstallation';
import PartLoanToFriend from '../../components/part/loanToFriend';
import PartEditLoan from '../../components/part/editLoan';
import PartEditUsage from '../../components/part/editUsage';
import PartViewUsage from '../../components/part/viewUsage';
import PartViewLoan from '../../components/part/viewLoan';
import PartDeleteFromIntallation from '../../components/part/deleteFromInstallation';

import EditInstallation from '../../components/installation/edit';
import InspectInstallation from '../../components/installation/inspect';
import EditInstallationPart from '../../components/installation/editPart';

import InspectionView from '../../components/inspection/view';
import EditInspection from '../../components/inspection/edit';

import Search from '../../components/search';
import FileViewer from '../../components/fileViewer';
import UrlViewer from '../../components/urlViewer';

import ViewText from '../../components/gui/viewText';

import MailSentView from '../../components/mailSent/view';
import SmsView from '../../components/sms/view';

import DyrejournalNewVisitor from '../../components/dyrejournal/newVisitor';
import DyrejournalNewAnimal from '../../components/dyrejournal/newAnimal';
import DyrejournalNewDrug from '../../components/dyrejournal/newDrug';
import DyrejournalNewProduct from '../../components/dyrejournal/newProduct';
import DyrejournalNewJournal from '../../components/dyrejournal/newJournal';
import DyrejournalNewPrescription from '../../components/dyrejournal/newPrescription';
import DyrejournalNewBooking from '../../components/dyrejournal/newBooking';
import DyrejournalNewSalePayment from '../../components/dyrejournal/newSalePayment';
import DyrejournalNewSaleComment from '../../components/dyrejournal/newSaleComment';

import DyrejournalParkSale from '../../components/dyrejournal/parkSale';
import DyrejournalPaySale from '../../components/dyrejournal/paySale';
import DyrejournalDiscard from '../../components/dyrejournal/discardSale';
import DyrejournalSalePayment from '../../components/dyrejournal/salePayment';
import DyrejournalSaleInvoice from '../../components/dyrejournal/saleInvoice';
import DyrejournalSaleOther from '../../components/dyrejournal/saleOther';
import DyrejournalViewJournal from '../../components/dyrejournal/viewJournal';
import DyrejournalViewAnimal from '../../components/dyrejournal/viewAnimal';
import DyrejournalAllVisits from '../../components/dyrejournal/allVisits';
import DyrejournalAllProducts from '../../components/dyrejournal/allProducts';
import DyrejournalReports from '../../components/dyrejournal/reports';
import DyrejournalJournals from '../../components/dyrejournal/journals';

import DyrejournalSendJournal from '../../components/dyrejournal/sendJournal';
import DyrejournalSendInvoice from '../../components/dyrejournal/sendInvoice';

import DyrejournalAddPrescriotion from '../../components/dyrejournal/addPrescription';
import DyrejournalAddDiagnose from '../../components/dyrejournal/addDiagnose';
import DyrejournalAddReferral from '../../components/dyrejournal/addReferral';

import DyrejournalViewPrescriotion from '../../components/dyrejournal/viewPrescription';
import DyrejournalEmailPrescriotion from '../../components/dyrejournal/emailPrescription';
import DyrejournalViewForm from '../../components/dyrejournal/viewForm';
import DyrejournalAddMedicin from '../../components/dyrejournal/addMedicine';
import DyrejournalAddAnimal from '../../components/dyrejournal/addAnimal';

import localUtil from '../../lib/util';

const FONTSIZE_MENU = '12px;';
const FONTSIZE_ICON = '23px;';

const initialState = {
    menu: {
		profile: false,
		main: false,
		showDashoard: false,
	},
	searchText: '',
};

@observer
class MenuV2 extends Component {
	userMenu = () => {
		const { appState, userStore } = this.props.stores;
		const { drawerHeightMedium } = appState;

		const { notifications = [] } = userStore;
		const unreadNotificatins = notifications.filter(e => !e.read);
		const unread = unreadNotificatins.length || 0;
        appState.openDrawer('menu', {
            height: drawerHeightMedium,
        }, 1);
	}

	render() {
		const { pathFixed } = this.props;
		const { appState, userStore } = this.props.stores;
		const { user = {}, isAdmin, isTester, isVeterinary } = userStore;
		const activeTeam = userStore.findTeam(user.activeTeam?.team || user.team, true, user.teams) || {};

		const {
			path, isCordova, apiServer, webocketUrl, apiServerChanged,
		} = appState;

        const image = localUtil.getImage({ user, team: activeTeam, priority: 'user' });

		return (<>
			<div class='d-flex justify-content-between'>
				<div
					class={`text-center p-2 ${path.match(/(^\/$|\/home-wall)/) ? 'border-bottom border-primary' : ''} text-nowrap overflow-hidden`}
					style='text-overflow: ellipsis; width: 20%;'
				>
					<a href={`${path === '/' ? '/home-wall' : '/'}`} class={`text-${path.match(/(^\/$|\/home-wall)/) ? 'primary' : 'secondary'}`}>
						<i class={`fa-${path.match(/(^\/$|\/home-wall)/) ? 'solid' : 'regular'} fa-house`} style={`font-size: ${FONTSIZE_ICON};`} /><br />
						<Text id='footer.home'>Home</Text>
					</a>
				</div>

				{isCordova && <>
					<div
						class={`text-center p-2 ${path.match(/\/workouts\/tracking/) ? 'border-bottom border-primary' : ''} text-nowrap overflow-hidden`}
						style='text-overflow: ellipsis; width: 20%;'
					>
						<a href='/workouts/tracking/' class={`text-${path.match(/\/workouts\/tracking/) ? 'primary' : 'secondary'}`}>
							<i class={`fa-${path.match(/\/workouts\/tracking/) ? 'solid' : 'regular'} fa-circle-dot`} style={`font-size: ${FONTSIZE_ICON};`} /><br />
							<Text id='footer.workouts-start'>Start</Text>
						</a>
					</div>
				</>}

				<div
					class={`text-center p-2 ${path.match(/(^\/users\/profile)/) ? 'border-bottom border-primary' : ''} text-nowrap overflow-hidden d-flex justify-content-center`}
					style='text-overflow: ellipsis; width: 20%;'
				>
					<a href='/users/profile' class={`text-${path.match(/\/users\/profile/) ? 'primary' : 'secondary'}`}>
						{/* <div
							class={`position-relative text-center border rounded-circle imageRounded imageRoundedThumb2 ml-1`}
							style={image ? `background-image: url("${image}"); background-size: cover;` : ''}
						>
							{!image && <i class='fas fa-users text-muted mt-2' style='font-size: 20px;' />}
						</div> */}
						<i class={`fa-${path.match(/(^\/users\/profile)/) ? 'solid' : 'regular'} fa-user-vneck`} style={`font-size: ${FONTSIZE_ICON};`} /><br />
						<Text id='footer.me'>Me</Text>
					</a>

					{user && user.id && path && <img
						src={`${apiServer}/api/pageview/${pathFixed}/${user.id}/${activeTeam?.id}/${new Date().getTime()}`}
						style='width: 1px; height: 1px; opacity: 0.1;'
					/>}
				</div>

				<div
					class={`text-center p-2 text-nowrap overflow-hidden d-flex justify-content-center`}
					style='text-overflow: ellipsis; width: 20%;'
				>
					<div class={`text-secondary`} onClick={this.userMenu}>
						<i class='fa-solid fa-bars' style={`font-size: ${FONTSIZE_ICON};`} /><br />
						<Text id='footer.menu'>Menu</Text>
					</div>
				</div>

			</div>
			{isCordova ? <>
				{user && user.id && <img
					src={`${apiServer}/api/pageview/iscordova.true/${user.id}/${activeTeam?.id}/${new Date().getTime()}`}
					style='width: 1px; height: 1px; opacity: 0.1;'
				/>}
			</> : <>
				{user && user.id && <img
					src={`${apiServer}/api/pageview/iscordova.false/${user.id}/${activeTeam?.id}/${new Date().getTime()}`}
					style='width: 1px; height: 1px; opacity: 0.1;'
				/>}
			</>}
		</>);
	}
}


@observer
class Navigation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...initialState,
        };
    }

	toggleDashboard = () => {
		const { appState } = this.props.stores;
		appState.toggleDashboard();
	}

	search = () => {
		const { searchText } = this.state;
		this.toggleDashboard();
		route(`/search/${searchText}`);
	}

    reloadApp = () => {
        window.location.reload(true);
    }

    render() {
		const { currentUrl } = this.props;
		const { searchText } = this.state;
		const { appState, userStore } = this.props.stores;
		const { user = {}, isAdmin, isTester, isVeterinary } = userStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');
		const {
			path, isCordova, apiServer, webocketUrl, apiServerChanged, showDashoard,
			showDrawer, drawerComponent, drawerProps = {},
			showDrawer2, drawerComponent2, drawerProps2 = {},
			showDrawer3, drawerComponent3, drawerProps3 = {},
			drawerHeightMedium,
			currentVersion, latestVersion,
		} = appState;

		let pathFixed = path.replace(/^\//, '');
		pathFixed = pathFixed.replace(/\/$/, '');
		pathFixed = pathFixed.replace(/\w{8,}-\w{4,}-\w{3,}-\w{3,}-\w{12,}/, 'uuid');
		pathFixed = pathFixed.replace(/\d+/g, 'id');
		// 8086094d-fbc3-44af-acc0-7c1ae9b5dce7
		pathFixed = pathFixed.replace(/\/$/, '');
		pathFixed = pathFixed.replace(/\//g, '.');

		if (pathFixed === '') {
			pathFixed = 'frontpage';
		}

		const activeTeam = userStore.findTeam(user.activeTeam?.team || user.team, true, user.teams) || {};
        const image = localUtil.getImage({ user, team: activeTeam });

		if (/^\/wizard/.test(currentUrl)) {
			return <>
				{user && user.id && path && <img src={`${apiServer}/api/pageview/${pathFixed}/${user.id}/${activeTeam?.id}/${new Date().getTime()}`} style='width: 1px; height: 1px; opacity: 0.1;' />}
			</>;
		}

		return (<>
			{[1, 2, 3, 4, 5, 6, 7, 8, 9].map(i => {
				return (<Fragment key={`drawer-container-${i}`}>
					{appState[`showDrawer${i}`] && <>
						<Drawer
							drawer={i}
							stores={this.props.stores}
							height={appState[`drawerProps${i}`].height}
							key={`drawer-${i}-${appState[`drawerProps${i}`].id}`}
							id={appState[`drawerProps${i}`].id}
							title={appState[`drawerProps${i}`].title}
							editFunction={appState[`drawerProps${i}`].editFunction}
							drawerComponent={appState[`drawerComponent${i}`]}
							onCloseClick={appState[`drawerProps${i}`].onCloseClick}
							borderRadius={appState[`drawerProps${i}`].borderRadius}
							showCloseButton={appState[`drawerProps${i}`].showCloseButton}
							showBackButton={appState[`drawerProps${i}`].showBackButton}
							backButtonText={appState[`drawerProps${i}`].backButtonText}
							callback={appState[`drawerProps${i}`].callback}
						>
							{appState[`drawerComponent${i}`] === 'bugReport' && <BugReport stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} />}
							{appState[`drawerComponent${i}`] === 'settings' && <Settings stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} />}
							{appState[`drawerComponent${i}`] === 'menu' && <Menu stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} />}
							{appState[`drawerComponent${i}`] === 'search' && <Search stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} />}

							{appState[`drawerComponent${i}`] === 'sendEmail' && <SendEmail stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} />}
							{appState[`drawerComponent${i}`] === 'sendSms' && <SendSms stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} />}
							{appState[`drawerComponent${i}`] === 'addToCalendar' && <AddToCalendar stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} />}
							{appState[`drawerComponent${i}`] === 'calendar' && <Calendar stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} />}

							{appState[`drawerComponent${i}`] === 'adminNewUser' && <AdminNewUser stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={true} />}
							{appState[`drawerComponent${i}`] === 'adminNewNews' && <AdminNewNews stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={true} />}
							{appState[`drawerComponent${i}`] === 'adminNewCustomer' && <AdminNewCustomer stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={true} />}
							{appState[`drawerComponent${i}`] === 'adminNewInstallation' && <AdminNewInstallation stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={true} />}
							{appState[`drawerComponent${i}`] === 'adminNewPart' && <AdminNewPart stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={true} />}
							{appState[`drawerComponent${i}`] === 'adminNewInspection' && <AdminNewInspection stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={true} />}
							{appState[`drawerComponent${i}`] === 'adminNewLoan' && <AdminNewLoan stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={true} />}
							{appState[`drawerComponent${i}`] === 'adminNewJournal' && <AdminNewJournal stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={true} />}
							{appState[`drawerComponent${i}`] === 'adminNewPrescription' && <AdminNewPrescription stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={true} />}
							{appState[`drawerComponent${i}`] === 'adminNewDrug' && <AdminNewDrug stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={true} />}
							{appState[`drawerComponent${i}`] === 'adminNewAnimal' && <AdminNewAnimal stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={true} />}
							{appState[`drawerComponent${i}`] === 'adminNewAnimalBreed' && <AdminNewAnimalBreed stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={true} />}
							{appState[`drawerComponent${i}`] === 'adminNewAnimalSpecie' && <AdminNewAnimalSpecie stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={true} />}
							{appState[`drawerComponent${i}`] === 'adminNewVisitor' && <AdminNewVisitor stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={true} />}
							{appState[`drawerComponent${i}`] === 'adminNewSale' && <AdminNewSale stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={true} />}
							{appState[`drawerComponent${i}`] === 'adminNewSalePayment' && <AdminNewSalePayment stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={true} />}
							{appState[`drawerComponent${i}`] === 'adminNewSaleReport' && <AdminNewSaleReport stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={true} />}
							{appState[`drawerComponent${i}`] === 'adminNewProduct' && <AdminNewProduct stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={true} />}
							{appState[`drawerComponent${i}`] === 'adminNewDiagnose' && <AdminNewDiagnose stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={true} />}
							{appState[`drawerComponent${i}`] === 'adminNewFile' && <AdminNewFile stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={true} />}
							{appState[`drawerComponent${i}`] === 'adminNewMedia' && <AdminNewMedia stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={true} />}
							{appState[`drawerComponent${i}`] === 'adminNewArticle' && <AdminNewArticle stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={true} />}
							{appState[`drawerComponent${i}`] === 'adminNewRace' && <AdminNewRace stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={true} />}
							{appState[`drawerComponent${i}`] === 'adminNewRaceClass' && <AdminNewRaceClass stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={true} />}
							{appState[`drawerComponent${i}`] === 'adminNewRaceClassContestant' && <AdminNewRaceClassContestant stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={true} />}
							{appState[`drawerComponent${i}`] === 'adminNewRaceResult' && <AdminNewRaceResult stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={true} />}
							{appState[`drawerComponent${i}`] === 'adminNewTimeTracker' && <AdminNewTimeTracker stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={true} />}
							{appState[`drawerComponent${i}`] === 'adminNewContact' && <AdminNewContact stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={true} />}
							{appState[`drawerComponent${i}`] === 'adminNewCalendar' && <AdminNewCalendar stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={true} />}
							{appState[`drawerComponent${i}`] === 'adminNewCalendarEvent' && <AdminNewCalendarEvent stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={true} />}
							{appState[`drawerComponent${i}`] === 'adminNewGpsDevice' && <AdminNewGpsDevice stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={true} />}
							{appState[`drawerComponent${i}`] === 'adminNewStream' && <AdminNewStream stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={true} />}
							{appState[`drawerComponent${i}`] === 'adminNewStreamDevice' && <AdminNewStreamDevice stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={true} />}
							{appState[`drawerComponent${i}`] === 'adminNewTextTemplate' && <AdminNewTextTemplate stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={true} />}
							{appState[`drawerComponent${i}`] === 'adminNewSms' && <AdminNewSms stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={true} />}
							{appState[`drawerComponent${i}`] === 'adminNewConvention' && <AdminNewConvention stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={true} />}
							{appState[`drawerComponent${i}`] === 'adminNewConventionResult' && <AdminNewConventionResult stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={true} />}
							{appState[`drawerComponent${i}`] === 'adminNewPayment' && <AdminNewPayment stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={true} />}

							{appState[`drawerComponent${i}`] === 'adminEditUser' && <AdminNewUser stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={false} />}
							{appState[`drawerComponent${i}`] === 'adminEditNews' && <AdminNewNews stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={false} />}
							{appState[`drawerComponent${i}`] === 'adminEditCustomer' && <AdminNewCustomer stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={false} />}
							{appState[`drawerComponent${i}`] === 'adminEditInstallation' && <AdminNewInstallation stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={false} />}
							{appState[`drawerComponent${i}`] === 'adminEditPart' && <AdminNewPart stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={false} />}
							{appState[`drawerComponent${i}`] === 'adminEditInspection' && <AdminNewInspection stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={false} />}
							{appState[`drawerComponent${i}`] === 'adminEditLoan' && <AdminNewLoan stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={false} />}
							{appState[`drawerComponent${i}`] === 'adminEditJournal' && <AdminNewJournal stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={false} />}
							{appState[`drawerComponent${i}`] === 'adminEditPrescription' && <AdminNewPrescription stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={false} />}
							{appState[`drawerComponent${i}`] === 'adminEditDrug' && <AdminNewDrug stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={false} />}
							{appState[`drawerComponent${i}`] === 'adminEditAnimal' && <AdminNewAnimal stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={false} />}
							{appState[`drawerComponent${i}`] === 'adminEditAnimalBreed' && <AdminNewAnimalBreed stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={false} />}
							{appState[`drawerComponent${i}`] === 'adminEditAnimalSpecie' && <AdminNewAnimalSpecie stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={false} />}
							{appState[`drawerComponent${i}`] === 'adminEditVisitor' && <AdminNewVisitor stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={false} />}
							{appState[`drawerComponent${i}`] === 'adminEditSale' && <AdminNewSale stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={false} />}
							{appState[`drawerComponent${i}`] === 'adminEditSalePayment' && <AdminNewSalePayment stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={false} />}
							{appState[`drawerComponent${i}`] === 'adminEditSaleReport' && <AdminNewSaleReport stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={false} />}
							{appState[`drawerComponent${i}`] === 'adminEditProduct' && <AdminNewProduct stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={false} />}
							{appState[`drawerComponent${i}`] === 'adminEditDiagnose' && <AdminNewDiagnose stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={false} />}
							{appState[`drawerComponent${i}`] === 'adminEditFile' && <AdminNewFile stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={false} />}
							{appState[`drawerComponent${i}`] === 'adminEditMedia' && <AdminNewMedia stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={false} />}
							{appState[`drawerComponent${i}`] === 'adminEditArticle' && <AdminNewArticle stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={false} />}
							{appState[`drawerComponent${i}`] === 'adminEditRace' && <AdminNewRace stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={false} />}
							{appState[`drawerComponent${i}`] === 'adminEditRaceClass' && <AdminNewRaceClass stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={false} />}
							{appState[`drawerComponent${i}`] === 'adminEditRaceClassContestant' && <AdminNewRaceClassContestant stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={false} />}
							{appState[`drawerComponent${i}`] === 'adminEditRaceResult' && <AdminNewRaceResult stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={false} />}
							{appState[`drawerComponent${i}`] === 'adminEditTimeTracker' && <AdminNewTimeTracker stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={false} />}
							{appState[`drawerComponent${i}`] === 'adminEditContact' && <AdminNewContact stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={false} />}
							{appState[`drawerComponent${i}`] === 'adminEditCalendar' && <AdminNewCalendar stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={false} />}
							{appState[`drawerComponent${i}`] === 'adminEditCalendarEvent' && <AdminNewCalendarEvent stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={false} />}
							{appState[`drawerComponent${i}`] === 'adminEditGpsDevice' && <AdminNewGpsDevice stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={false} />}
							{appState[`drawerComponent${i}`] === 'adminEditStream' && <AdminNewStream stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={false} />}
							{appState[`drawerComponent${i}`] === 'adminEditStreamDevice' && <AdminNewStreamDevice stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={false} />}
							{appState[`drawerComponent${i}`] === 'adminEditCalendarAvailability' && <AdminNewCalendarAvailability stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={false} />}
							{appState[`drawerComponent${i}`] === 'adminEditTextTemplate' && <AdminNewTextTemplate stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={false} />}
							{appState[`drawerComponent${i}`] === 'adminEditSms' && <AdminNewSms stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={false} />}

							{appState[`drawerComponent${i}`] === 'adminEditConvention' && <AdminNewConvention stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={false} />}
							{appState[`drawerComponent${i}`] === 'adminEditConventionResult' && <AdminNewConventionResult stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={false} />}
							{appState[`drawerComponent${i}`] === 'adminEditPayment' && <AdminNewPayment stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={false} />}

							{appState[`drawerComponent${i}`] === 'adminViewGpsPosition' && <AdminViewGpsPosition stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={true} />}

							{appState[`drawerComponent${i}`] === 'help' && <Help stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} />}

							{appState[`drawerComponent${i}`] === 'addArrayObject' && <AddArrayObject stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={false} />}
							{appState[`drawerComponent${i}`] === 'editArrayObject' && <EditArrayObject stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={false} />}
							{appState[`drawerComponent${i}`] === 'addArrayElement' && <AddArrayElement stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={false} />}
							{appState[`drawerComponent${i}`] === 'editField' && <EditField stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={false} />}

							{appState[`drawerComponent${i}`] === 'viewImage' && <ImageView stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} />}
							{appState[`drawerComponent${i}`] === 'imageFullscreen' && <ImageFullscreen stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} />}

							{appState[`drawerComponent${i}`] === 'viewNews' && <NewsView stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} />}
							{appState[`drawerComponent${i}`] === 'newsMenu' && <NewsMenu stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} />}
							{appState[`drawerComponent${i}`] === 'newsEdit' && <NewsEdit stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} />}
							{appState[`drawerComponent${i}`] === 'newsNew' && <NewsEdit stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={true} />}
							{appState[`drawerComponent${i}`] === 'adminListNews' && <NewsAdminList stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} />}

							{appState[`drawerComponent${i}`] === 'viewPart' && <PartView stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} />}
							{appState[`drawerComponent${i}`] === 'editPart' && <PartEdit stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={false} />}
							{appState[`drawerComponent${i}`] === 'newPart' && <PartEdit stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={true} />}
							{appState[`drawerComponent${i}`] === 'partAddUsageData' && <PartAddUsageData stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={true} />}
							{appState[`drawerComponent${i}`] === 'sortPart' && <PartSort stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={true} />}

							{appState[`drawerComponent${i}`] === 'editMedia' && <MediaEdit stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={false} />}
							{appState[`drawerComponent${i}`] === 'newMedia' && <MediaEdit stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={true} />}
							{appState[`drawerComponent${i}`] === 'viewMedia' && <MediaView stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} />}

							{appState[`drawerComponent${i}`] === 'editArticle' && <ArticleEdit stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={false} />}
							{appState[`drawerComponent${i}`] === 'newArticle' && <ArticleEdit stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={true} />}
							{appState[`drawerComponent${i}`] === 'viewArticle' && <ArticleView stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} />}
							{appState[`drawerComponent${i}`] === 'listArticle' && <ArticleList stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} />}
							{appState[`drawerComponent${i}`] === 'adminListArticle' && <ArticleAdminList stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} />}

							{appState[`drawerComponent${i}`] === 'newSubmittedApplication' && <SubmittedApplicationEdit stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} />}
							{appState[`drawerComponent${i}`] === 'editSubmittedApplication' && <SubmittedApplicationEdit stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} />}
							{appState[`drawerComponent${i}`] === 'viewSubmittedApplication' && <SubmittedApplicationView stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} />}
							{appState[`drawerComponent${i}`] === 'viewSubmittedApplicationAdmin' && <SubmittedApplicationViewAdmin stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} />}

							{appState[`drawerComponent${i}`] === 'editContact' && <ContactEdit stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={false} />}
							{appState[`drawerComponent${i}`] === 'newContact' && <ContactEdit stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={true} />}
							{appState[`drawerComponent${i}`] === 'viewContact' && <ContactView stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} />}
							{appState[`drawerComponent${i}`] === 'listContact' && <ContactList stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} />}

							{appState[`drawerComponent${i}`] === 'editFile' && <FileEdit stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={false} />}
							{appState[`drawerComponent${i}`] === 'newFile' && <FileEdit stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={true} />}
							{appState[`drawerComponent${i}`] === 'viewFile' && <FileView stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} />}
							{appState[`drawerComponent${i}`] === 'listFile' && <FileList stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} />}
							{appState[`drawerComponent${i}`] === 'listFileTable' && <FileListTable stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} />}

							{appState[`drawerComponent${i}`] === 'editTimeTracker' && <TimeTrackerEdit stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={false} />}
							{appState[`drawerComponent${i}`] === 'newTimeTracker' && <TimeTrackerEdit stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={true} />}
							{appState[`drawerComponent${i}`] === 'viewTimeTracker' && <TimeTrackerView stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} />}

							{appState[`drawerComponent${i}`] === 'editCalendar' && <CalendarEdit stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={false} />}
							{appState[`drawerComponent${i}`] === 'newCalendar' && <CalendarEdit stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={true} />}
							{appState[`drawerComponent${i}`] === 'viewCalendar' && <CalendarView stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} />}

							{appState[`drawerComponent${i}`] === 'editConvention' && <ConventionEdit stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={false} />}
							{appState[`drawerComponent${i}`] === 'newConvention' && <ConventionEdit stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={true} />}
							{appState[`drawerComponent${i}`] === 'viewConvention' && <ConventionView stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} />}

							{appState[`drawerComponent${i}`] === 'editConventionParticipant' && <ConventionEditParticipant stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={false} />}
							{appState[`drawerComponent${i}`] === 'editConventionStage' && <ConventionEditStage stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={false} />}
							{appState[`drawerComponent${i}`] === 'editConventionResultImage' && <ConventionResultEditImage stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={false} />}
							{appState[`drawerComponent${i}`] === 'viewConventionResultImage' && <ConventionResultViewImage stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={false} />}

							{appState[`drawerComponent${i}`] === 'editCalendarEvent' && <CalendarEventEdit stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={false} />}
							{appState[`drawerComponent${i}`] === 'newCalendarEvent' && <CalendarEventEdit stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={true} />}
							{appState[`drawerComponent${i}`] === 'viewCalendarEvent' && <CalendarEventView stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} />}

							{appState[`drawerComponent${i}`] === 'editCalendarAvailability' && <CalendarAvailabilityEdit stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={false} />}
							{appState[`drawerComponent${i}`] === 'newCalendarAvailability' && <CalendarAvailabilityEdit stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={true} />}
							{appState[`drawerComponent${i}`] === 'viewCalendarAvailability' && <CalendarAvailabilityView stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} />}

							{appState[`drawerComponent${i}`] === 'editTextTemplate' && <TextTemplateEdit stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={false} />}
							{appState[`drawerComponent${i}`] === 'newTextTemplate' && <TextTemplateEdit stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={true} />}
							{appState[`drawerComponent${i}`] === 'viewTextTemplate' && <TextTemplateView stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} />}

							{appState[`drawerComponent${i}`] === 'viewMailSent' && <MailSentView stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} />}
							{appState[`drawerComponent${i}`] === 'viewSms' && <SmsView stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} />}

							{/* {appState[`drawerComponent${i}`] === 'editTextTemplate' && <TextTemplateEdit stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={false} />}
							{appState[`drawerComponent${i}`] === 'newTextTemplate' && <TextTemplateEdit stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={true} />}
							{appState[`drawerComponent${i}`] === 'viewTextTemplate' && <TextTemplateView stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} />} */}

							{appState[`drawerComponent${i}`] === 'viewRaceClassContestant' && <RaceClassContestantView stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} />}
							{appState[`drawerComponent${i}`] === 'viewRaceClassCheckpoint' && <RaceClassCheckpointView stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} />}
							{appState[`drawerComponent${i}`] === 'adminViewRaceClassContestant' && <RaceClassContestantAdminView stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} />}
							{appState[`drawerComponent${i}`] === 'adminListRaceClassContestant' && <RaceClassContestantAdminList stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} />}
							{appState[`drawerComponent${i}`] === 'paySignupFee' && <RaceClassPaySignupFee stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} />}
							{appState[`drawerComponent${i}`] === 'paySignup' && <RaceClassPaySignup stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} />}
							{appState[`drawerComponent${i}`] === 'payRest' && <RaceClassPayRest stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} />}
							{appState[`drawerComponent${i}`] === 'payGeneric' && <RaceClassPayGeneric stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} />}
							{appState[`drawerComponent${i}`] === 'viewRaceMap' && <RaceViewMap stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} />}

							{appState[`drawerComponent${i}`] === 'addPartToInstallation' && <PartAddToInstallation stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} />}
							{appState[`drawerComponent${i}`] === 'loanPartToFriend' && <PartLoanToFriend stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} />}
							{appState[`drawerComponent${i}`] === 'editLoan' && <PartEditLoan stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} />}
							{appState[`drawerComponent${i}`] === 'viewLoan' && <PartViewLoan stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} />}
							{appState[`drawerComponent${i}`] === 'editUsage' && <PartEditUsage stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} />}
							{appState[`drawerComponent${i}`] === 'viewUsage' && <PartViewUsage stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} />}
							{appState[`drawerComponent${i}`] === 'deletePartFromInstallation' && <PartDeleteFromIntallation stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} />}

							{appState[`drawerComponent${i}`] === 'viewCustomer' && <CustomerView stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} />}
							{appState[`drawerComponent${i}`] === 'editCustomer' && <CustomerEdit stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={false} />}
							{appState[`drawerComponent${i}`] === 'editCustomerUser' && <CustomerUserEdit stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={false} />}
							{appState[`drawerComponent${i}`] === 'newCustomerUser' && <CustomerUserEdit stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={true} />}

							{appState[`drawerComponent${i}`] === 'editInstallation' && <EditInstallation stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={false} />}
							{appState[`drawerComponent${i}`] === 'editInstallationPart' && <EditInstallationPart stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={false} />}
							{appState[`drawerComponent${i}`] === 'inspectInstallation' && <InspectInstallation stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={false} />}
							{appState[`drawerComponent${i}`] === 'newInstallation' && <EditInstallation stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={true} />}

							{appState[`drawerComponent${i}`] === 'viewInspection' && <InspectionView stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} />}
							{appState[`drawerComponent${i}`] === 'newInspection' && <EditInspection stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={true} />}
							{appState[`drawerComponent${i}`] === 'editInspection' && <EditInspection stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} isNew={false} />}

							{appState[`drawerComponent${i}`] === 'viewText' && <ViewText stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} />}

							{appState[`drawerComponent${i}`] === 'newVisitor' && <DyrejournalNewVisitor stores={this.props.stores} drawerLevel={i} isNew={true} {...appState[`drawerProps${i}`]} />}
							{appState[`drawerComponent${i}`] === 'newAnimal' && <DyrejournalNewAnimal stores={this.props.stores} drawerLevel={i} isNew={true} {...appState[`drawerProps${i}`]} />}
							{appState[`drawerComponent${i}`] === 'newDrug' && <DyrejournalNewDrug stores={this.props.stores} drawerLevel={i} isNew={true} {...appState[`drawerProps${i}`]} />}
							{appState[`drawerComponent${i}`] === 'newProduct' && <DyrejournalNewProduct stores={this.props.stores} drawerLevel={i} isNew={true} {...appState[`drawerProps${i}`]} />}
							{appState[`drawerComponent${i}`] === 'newJournal' && <DyrejournalNewJournal stores={this.props.stores} drawerLevel={i} isNew={true} {...appState[`drawerProps${i}`]} />}
							{appState[`drawerComponent${i}`] === 'newPrescription' && <DyrejournalNewPrescription stores={this.props.stores} drawerLevel={i} isNew={true} {...appState[`drawerProps${i}`]} />}
							{appState[`drawerComponent${i}`] === 'newBooking' && <DyrejournalNewBooking stores={this.props.stores} drawerLevel={i} isNew={true} {...appState[`drawerProps${i}`]} />}
							{appState[`drawerComponent${i}`] === 'newSaleComment' && <DyrejournalNewSaleComment stores={this.props.stores} drawerLevel={i} {...appState[`drawerProps${i}`]} />}

							{appState[`drawerComponent${i}`] === 'sendJournal' && <DyrejournalSendJournal stores={this.props.stores} drawerLevel={i} isNew={true} {...appState[`drawerProps${i}`]} />}
							{appState[`drawerComponent${i}`] === 'sendInvoice' && <DyrejournalSendInvoice stores={this.props.stores} drawerLevel={i} isNew={true} {...appState[`drawerProps${i}`]} />}

							{appState[`drawerComponent${i}`] === 'parkSale' && <DyrejournalParkSale stores={this.props.stores} drawerLevel={i} isNew={false} {...appState[`drawerProps${i}`]} />}
							{appState[`drawerComponent${i}`] === 'paySale' && <DyrejournalPaySale stores={this.props.stores} drawerLevel={i} isNew={false} {...appState[`drawerProps${i}`]} />}
							{appState[`drawerComponent${i}`] === 'discardSale' && <DyrejournalDiscard stores={this.props.stores} drawerLevel={i} isNew={false} {...appState[`drawerProps${i}`]} />}
							{appState[`drawerComponent${i}`] === 'salePayment' && <DyrejournalSalePayment stores={this.props.stores} drawerLevel={i} {...appState[`drawerProps${i}`]} />}
							{appState[`drawerComponent${i}`] === 'saleInvoice' && <DyrejournalSaleInvoice stores={this.props.stores} drawerLevel={i} {...appState[`drawerProps${i}`]} />}
							{appState[`drawerComponent${i}`] === 'saleOther' && <DyrejournalSaleOther stores={this.props.stores} drawerLevel={i} {...appState[`drawerProps${i}`]} />}
							{appState[`drawerComponent${i}`] === 'viewJournal' && <DyrejournalViewJournal stores={this.props.stores} drawerLevel={i} {...appState[`drawerProps${i}`]} />}
							{appState[`drawerComponent${i}`] === 'viewAnimal' && <DyrejournalViewAnimal stores={this.props.stores} drawerLevel={i} {...appState[`drawerProps${i}`]} />}

							{appState[`drawerComponent${i}`] === 'editVisitor' && <DyrejournalNewVisitor stores={this.props.stores} drawerLevel={i} isNew={false} {...appState[`drawerProps${i}`]} />}
							{appState[`drawerComponent${i}`] === 'editAnimal' && <DyrejournalNewAnimal stores={this.props.stores} drawerLevel={i} isNew={false} {...appState[`drawerProps${i}`]} />}
							{appState[`drawerComponent${i}`] === 'editProduct' && <DyrejournalNewProduct stores={this.props.stores} drawerLevel={i} isNew={false} {...appState[`drawerProps${i}`]} />}
							{appState[`drawerComponent${i}`] === 'editSalePayment' && <DyrejournalNewSalePayment stores={this.props.stores} drawerLevel={i} isNew={false} {...appState[`drawerProps${i}`]} />}

							{appState[`drawerComponent${i}`] === 'addPrescription' && <DyrejournalAddPrescriotion stores={this.props.stores} drawerLevel={i} {...appState[`drawerProps${i}`]} />}
							{appState[`drawerComponent${i}`] === 'addDiagnose' && <DyrejournalAddDiagnose stores={this.props.stores} drawerLevel={i} {...appState[`drawerProps${i}`]} />}
							{appState[`drawerComponent${i}`] === 'addReferral' && <DyrejournalAddReferral stores={this.props.stores} drawerLevel={i} {...appState[`drawerProps${i}`]} />}

							{appState[`drawerComponent${i}`] === 'viewPrescription' && <DyrejournalViewPrescriotion stores={this.props.stores} drawerLevel={i} {...appState[`drawerProps${i}`]} />}
							{appState[`drawerComponent${i}`] === 'emailPrescription' && <DyrejournalEmailPrescriotion stores={this.props.stores} drawerLevel={i} {...appState[`drawerProps${i}`]} />}
							{appState[`drawerComponent${i}`] === 'viewForm' && <DyrejournalViewForm stores={this.props.stores} drawerLevel={i} {...appState[`drawerProps${i}`]} />}
							{appState[`drawerComponent${i}`] === 'addMedicine' && <DyrejournalAddMedicin stores={this.props.stores} drawerLevel={i} {...appState[`drawerProps${i}`]} />}
							{appState[`drawerComponent${i}`] === 'addAnimal' && <DyrejournalAddAnimal stores={this.props.stores} drawerLevel={i} {...appState[`drawerProps${i}`]} />}
						</Drawer>
					</>}
				</Fragment>);
			})}

			{[1, 2, 3, 4, 5].map(i => {
				return (<Fragment key={`drawer-right-container-${i}`}>
					{appState[`showDrawerRight${i}`] && <>
						<DrawerRight
							drawer={i}
							stores={this.props.stores}
							height={appState[`drawerProps${i}`].height}
							id={appState[`drawerProps${i}`].id}
							key={`drawer-right--${i}-${appState[`drawerProps${i}`].id}`}
							title={appState[`drawerProps${i}`].title}
							editFunction={appState[`drawerProps${i}`].editFunction}
							drawerComponent={appState[`drawerComponent${i}`]}
							onCloseClick={appState[`drawerProps${i}`].onCloseClick}
							borderRadius={appState[`drawerProps${i}`].borderRadius}
							showCloseButton={appState[`drawerProps${i}`].showCloseButton}
							showBackButton={appState[`drawerProps${i}`].showBackButton}
							backButtonText={appState[`drawerProps${i}`].backButtonText}
							rightButtonText={appState[`drawerProps${i}`].rightButtonText}
							rightButtonClick={appState[`drawerProps${i}`].rightButtonClick}
							callback={appState[`drawerProps${i}`].callback}
						>
							{appState[`drawerComponent${i}`] === 'search' && <Search stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} />}
							{appState[`drawerComponent${i}`] === 'fileViewer' && <FileViewer stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} />}
							{appState[`drawerComponent${i}`] === 'urlViewer' && <UrlViewer stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} />}
							{appState[`drawerComponent${i}`] === 'raceSignup' && <RaceSignup stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} />}

							{appState[`drawerComponent${i}`] === 'calendar' && <Calendar stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} />}

							{appState[`drawerComponent${i}`] === 'viewImage' && <ImageView stores={this.props.stores} {...appState[`drawerProps${i}`]} drawerLevel={i} />}

							{appState[`drawerComponent${i}`] === 'newJournal' && <DyrejournalNewJournal stores={this.props.stores} drawerLevel={i} isNew={true} {...appState[`drawerProps${i}`]} />}
							{appState[`drawerComponent${i}`] === 'editJournal' && <DyrejournalNewJournal stores={this.props.stores} drawerLevel={i} isNew={false} {...appState[`drawerProps${i}`]} />}
							{appState[`drawerComponent${i}`] === 'allVisits' && <DyrejournalAllVisits stores={this.props.stores} drawerLevel={i} {...appState[`drawerProps${i}`]} />}
							{appState[`drawerComponent${i}`] === 'allProducts' && <DyrejournalAllProducts stores={this.props.stores} drawerLevel={i} {...appState[`drawerProps${i}`]} />}
							{appState[`drawerComponent${i}`] === 'allFiles' && <DyrejournalAllFiles stores={this.props.stores} drawerLevel={i} {...appState[`drawerProps${i}`]} />}
							{appState[`drawerComponent${i}`] === 'reports' && <DyrejournalReports stores={this.props.stores} drawerLevel={i} {...appState[`drawerProps${i}`]} />}
							{appState[`drawerComponent${i}`] === 'journals' && <DyrejournalJournals stores={this.props.stores} drawerLevel={i} {...appState[`drawerProps${i}`]} />}
						</DrawerRight>
					</>}
				</Fragment>);
			})}

			<div
				class={`navbar-themed fixed-bottom pb-0 ${darkmode ? 'bg-darkmode' : 'bg-lightmode'}`}
				style={`
					font-size: ${FONTSIZE_MENU};
				`}
			>
                {/* <MenuV2 stores={this.props.stores} pathFixed={pathFixed} isCordova={isCordova} /> */}

				{isAdmin && apiServerChanged && <div class='position-absolute badge badge-primary' style='bottom: 2px; right: 0;'>
					<span class='font-weight-lighter'>apiServer:</span> {apiServer} -
					<span class='font-weight-lighter'>webocketUrl:</span> {webocketUrl}
				</div>}

				{currentVersion < latestVersion && <>
					<div
						class='position-absolute badge badge-success rounded-pill px-3 py-2'
						style='bottom: 100px; left: 50%; transform: translateX(-50%); font-size: 0.9rem; line-height: 1.0rem; cursor: pointer;'
						onClick={this.reloadApp}
					>
						<div class='d-flex flex-row'>
							<i class='fa-duotone fa-face-smile-hearts' style='font-size: 2.2rem;' />
							<div class='ml-3 text-left'>
								<span class='font-weight-bold'>
									<Text id='version.new-available'>New version available</Text>
								</span>
								<br />
								<span class='font-weight-normal'>
									<Text id='version.click-to-update'>Click here or refresh page to update.</Text>
								</span>
								<br />
								{/* <div class='font-weight-lighter text-center'>
									<small>
										{currentVersion} <i class='fa-duotone fa-arrow-right' /> {latestVersion}
									</small>
								</div> */}
							</div>
						</div>
						{/* {JSON.stringify(currentVersion)}
						{JSON.stringify(latestVersion)} */}
					</div>
				</>}

			</div>
		</>);
	}
}

export default Navigation;
