import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';
import Markdown from 'preact-markdown';

import localUtil from '../../lib/util';
import fields from '../../lib/fields';

import FormFields from '../../components/form/fields';
import Input from '../../components/form/input';

@observer
class EditField extends Component {
  	constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { week, isNew, drawerLevel, holdingStore, sections, fields, objectName, object, onInputcallback } = this.props;
        const { userStore, saleStore } = this.props.stores;
        const { user, isAdmin } = userStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');
        // const { animalJournals } = saleStore;

        return (<>
            <div class='w-100 d-flex justify-content-center'>
                <div class='w-100' style='max-width: 960px; padding-top: 20px; padding-bottom: 100px;'>
                    {/* <div class='w-100 d-flex flex-column justify-content-start'>
                        <xmp>{JSON.stringify(animalJournals, null, 2)}</xmp>
                    </div> */}
                    {/* <xmp>{JSON.stringify(newCustomer, null, 4)}</xmp> */}
                    {/* <h5 class='ml-3 mb-3'>
                        {isNew ? <Text id='journal.new'>New journal</Text> : <Text id='journal.edit'>Edit journal</Text>}
                    </h5> */}
                    <div class='bg-light d-flex flex-column justify-content-start h-100 pt-3'>
                        <FormFields
                            stores={this.props.stores}
                            sections={sections}
                            fields={fields}
                            holdingStore={holdingStore}
                            object={object}
                            objectName={objectName}
                            isNew={false}
                            drawerLevel={drawerLevel}
                            isAdmin={false}
                            onInputcallback={onInputcallback}
                        />
                    </div>
                    {isNew && <div class='w-100 d-flex flex-column justify-content-start mt-3 px-4 py-3 bg-light fixed-bottom' style={`bottom: 0; left: 0;`}>
                        <button type='button' class='btn btn-primary rounded-pill' onClick={this.createJournal}>
                            {isNew ? <Text id='journal.create'>Create journal</Text> : <Text id='journal.save'>Save journal</Text>}
                        </button>
                    </div>}
                </div>
            </div>
        </>);
    }
}

export default EditField;
