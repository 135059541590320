import { observable, configure, action, computed } from 'mobx';
import LocalModel from './localModel';

import util from 'preact-util';
import { route } from 'preact-router';
import PubSub, { topics } from '../lib/pubsub';

configure({ enforceActions: 'always' });

function formatDateToICal(dateString) {
    const date = new Date(dateString);
    const yyyyMMdd = date.toISOString().slice(0, 10).replace(/-/g, '');
    const HHmmss = date.toISOString().slice(11, 19).replace(/:/g, '');
    return `${yyyyMMdd}T${HHmmss}Z`;
}

function escapeICalText(text) {
    return text
      .replace(/\\/g, '\\\\') // Escape backslashes
      .replace(/;/g, '\\;')   // Escape semicolons
      .replace(/,/g, '\\,')   // Escape commas
      .replace(/\n/g, '\\n'); // Replace newlines with literal \n
}

function foldLine(line) {
    const maxLength = 75;
    let result = '';
    while (line.length > maxLength) {
      result += line.slice(0, maxLength) + '\r\n ';
      line = line.slice(maxLength);
    }
    result += line;
    return result;
}

function generateICalContent(eventData) {
    const {
        uuidv4,
        createdDate,
        start,
        end,
        title,
        body,
        calendarId,
        animals,
        customer
    } = eventData;

    const dtstamp = formatDateToICal(createdDate || new Date());
    const dtstart = formatDateToICal(start);
    const dtend = formatDateToICal(end);
    const uid = uuidv4 || `${eventData.id}@yourdomain.com`;
    const summary = title || 'No Title';
    const descriptionLines = [];

    descriptionLines.push(body || 'No Description');
    if (animals && animals.length > 0) {
        descriptionLines.push(`Animal: ${animals[0].name}`);
    }
    descriptionLines.push(`Customer ID: ${customer}`);
    const description = escapeICalText(descriptionLines.join('\n'));

    return `
BEGIN:VCALENDAR
VERSION:2.0
PRODID:-//Dyrejournal//Dyrejournal//EN
BEGIN:VEVENT
UID:${uid}
DTSTAMP:${dtstamp}
DTSTART:${dtstart}
DTEND:${dtend}
SUMMARY:${summary}
DESCRIPTION:${description}
ORGANIZER;CN=${calendarId}:MAILTO:no-reply@yourdomain.com
END:VEVENT
END:VCALENDAR
`.trim();
}

class CalendarEventStore extends LocalModel {
    constructor() {
        super('calendarEvent', {
            namePlural: 'calendarEvents',
            namePluralReal: 'calendarEvents',
            sort: '-createdDate',
            limit: 2000,
            api: {
                search: {
                    url: '/api/calendarevents/',
                    params: {
                        limit: 2000,
                        sort: '-createdDate',
                    },
                },
                load: {
                    url: '/api/calendarevents/',
                    params: {},
                },
                save: {
                    url: '/api/calendarevents/',
                    params: {},
                },
                delete: {
                    url: '/api/calendarevents/',
                    params: {},
                },
            },
        });
    }

    @observable newCalendarEvent = {};

    @observable calendarEvent = {};

    @observable calendarEvents = [];

    @observable calendars = [];

    @observable animals = [];

    @observable visitors = [];

    @observable clientHours = [];

    @observable adminAnimals = [];

    @observable totalHours = [];

    @observable animalBookings = [];

    @observable recurringList = [];

    @observable creators = [];

    @observable currentTime = null;

    @observable currentTimeOffset = null;

    @observable href = null;

    @observable icsContent = null;

    getEvents(date, calendarId) {
        const { calendarEvents, recurringList } = this;
        const start = new Date(date);
        const end = new Date(date);
        // Set end to 59 minutes and 59 seconds
        end.setMinutes(end.getMinutes() + 59);
        end.setSeconds(end.getSeconds() + 59);

        const allCalendarIds = [];
        if (util.isArray(calendarId)) {
            allCalendarIds.push(...calendarId);
        } else {
            allCalendarIds.push(calendarId);
        }

        const events = calendarEvents
            .filter(e => allCalendarIds.includes(e.calendarId))
            // .filter(e => calendarId === e.calendarId)
            .filter(e => {
                const eventStart = new Date(e.start);
                return eventStart >= start && eventStart <= end;
            });

        if (recurringList && recurringList.length > 0) {
            // console.log('recurringList', recurringList);
            const recurrList = this.recurringList
                .filter(e => allCalendarIds.includes(e.calendarId))
                // .filter(e => calendarId === e.calendarId)
                .filter(e => {
                    const eventStart = new Date(e.start);
                    console.log('eventStart', eventStart, start, end)
                    return eventStart >= start && eventStart <= end;
                });
            // console.log('recurrList', recurrList);
            events.push(...recurrList);
        }
        return events;
    }

    @action
    isOverlapping() {
        const { calendarEvents } = this;
        calendarEvents.forEach(e => {
            e.overlapping = false;
            e.overlappingCnt = 0;
            e.overlappingIdx = 0;
            const start = new Date(e.start);
            const end = new Date(e.end);
            // const adjustedStart = new Date(start.getTime() - 60000); // Subtract 1 minute (60,000 milliseconds)
            // const adjustedEnd = new Date(end.getTime() + 60000); // Add 1 minute

            const events = calendarEvents
                .filter(e2 => e.calendarId === e2.calendarId)
                .filter(e2 => e2.id !== e.id)
                .filter(e2 => {
                    // Find overlapping events for this event
                    const eventStart = new Date(e2.start);
                    const eventEnd = new Date(e2.end);
// console.log({ eventStart, eventEnd, start, end })
                    const isOverlapping = (start < eventEnd && end > eventStart);
                    return isOverlapping;
                });
            if (events.length > 0) {
                e.overlapping = true;
                e.overlappingCnt = events.length;
                // Find the largest overlapping idx
                e.overlappingIdx = events.reduce((acc, cur) => {
                    return Math.max(acc, cur.overlappingIdx || 0);
                }, 0) + 1;
                // e.overlappingEvents = events;
            }
        });
    }

    async sendSMS(id, text) {
        const response = await util.fetchApi(`/api/calendarevents/${id}/sms`, { method: 'GET' }, { text });
        switch (response.status) {
            case 200:
                return response;
            default:
                return 'Error';
        }
    }

    @action
    handleGenerateICS(eventData) {
        if (this.href) {
            URL.revokeObjectURL(this.href);
        }
        const icsContent = generateICalContent(eventData);
        this.icsContent = icsContent;
        const blob = new Blob([icsContent], { type: 'text/calendar;charset=utf-8' });
        const url = URL.createObjectURL(blob);
        this.href = url;
    }

}

const store = new CalendarEventStore();
export default store;
