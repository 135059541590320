import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';
import Markdown from 'preact-markdown';
import { QRCodeSVG } from 'qrcode.react';
import md5 from 'crypto-js/md5'
import { route } from 'preact-router';

import localUtil from '../../lib/util';
import fields from '../../lib/fields';

import FormFields from '../../components/form/fields';
import Input from '../../components/form/input';
import ImageScroller from '../../components/imagescroller';
import InspectionStatus from '../../components/inspection/status';

import RaceClassList from '../../components/raceClass/list';

import GuiList from '../../components/gui/list';
import GuiInfoBox from '../../components/gui/infoBox';

import NewsList from '../../components/news/list';
import RaceResultList from '../../components/race/resultsMini';
import StreamingList from '../../components/streaming/listMini';

function displayImg(img, props, size = '150x') {
    if (typeof img !== 'object') {
        return undefined;
    }
    const imgSrc = `${img.s3XLargeLink}`;
    return imgSrc;
}

@withText(props => ({
    titleText: <Text id='race.title'>Races</Text>,

    infoText: <Text id='race.info'>Info</Text>,
    programText: <Text id='race.program'>Program</Text>,
    termsText: <Text id='race.terms'>Terms</Text>,
    trackText: <Text id='race.track'>Track</Text>,
    rulesText: <Text id='race.rules'>Rules</Text>,
    priceText: <Text id='race.price'>Prices</Text>,
    qualifyText: <Text id='race.qualify'>Qualifying</Text>,
    checkpointsInfoText: <Text id='race.checkpointsInfo'>Checkpoints Info</Text>,
    veterinaryText: <Text id='race.veterinary'>Veterinary</Text>,
    declarationText: <Text id='race.declaration'>Declaration</Text>,
    emergencyText: <Text id='race.emergency'>Emergency</Text>,
    termsOfSaleText: <Text id='race-class.terms-of-sale'>Terms of sale</Text>,

    raceStartText: <Text id='race.race-start'>Race start</Text>,
    signupStartText: <Text id='race.signup-start'>Signup start</Text>,
    distanceText: <Text id='race.distance'>Distance</Text>,
    dogsText: <Text id='race.dogs'>Dogs</Text>,
    restText: <Text id='race.rest'>Rest</Text>,
    totalFeeText: <Text id='race.total-fee'>Total fee</Text>,
    signupFeeText: <Text id='race.signup-fee'>Signup fee</Text>,

    resultText: <Text id='race.results-menu'>Results</Text>,
    signedupText: <Text id='race.signups'>Signups</Text>,
    trackingText: <Text id='race.tracking'>Tracking</Text>,
    newsText: <Text id='race.news'>News</Text>,
    streamingText: <Text id='race.streaming'>Streaming</Text>,

    signupsText: <Text id='race.signups'>Signups</Text>,
    editRaceText: <Text id='race.edit-race'>Edit race</Text>,
    addRaceResultText: <Text id='race-class.add-race-result'>Add RaceResult</Text>,
    addRaceEventText: <Text id='race-class.add-race-event'>Add Race Event</Text>,
    checkinKioskText: <Text id='race-class.checkin-kiosk'>Checkin Kiosk</Text>,

    raceSignupStartText: <Text id='events.race-signup-start'>Race signup start</Text>,
    raceSignupEndText: <Text id='events.race-signup-end'>Race signup end</Text>,
    dashboardText: <Text id='events.race-signup-dashboard'>Race Dashboard</Text>,
}))
@observer
class RaceDetail extends Component {
  	constructor(props) {
        super(props);
        this.state = {
            infoList: [],
            resultList: [],
            adminList: [],
        };
    }

    async loadRace(props = this.props) {
        const {
            infoText,
            programText,
            checkpointsInfoText,
            termsText,
            trackText,
            rulesText,
            priceText,
            qualifyText,
            veterinaryText,
            declarationText,
            emergencyText,
            termsOfSaleText,
            resultText,
            signedupText,
            trackingText,
            newsText,
            streamingText,
            signupsText,
            editRaceText,
            addRaceResultText,
            addRaceEventText,
            checkinKioskText,
            dashboardText,
        } = this.props;
		const { raceStore, userStore, appState } = props.stores;
        const { language: appStateLanguage } = appState;
        const { id } = props;
        raceStore.setRefreshPage(() => {
            this.loadAll();
        });
        raceStore.updateKeyValue('isRaceAdmin', false);

        raceStore.updateKeyValue('race', {});
        raceStore.updateKeyValue('races', []);
        raceStore.updateKeyValue('raceClassList', []);
        raceStore.updateKeyValue('totalContestants', []);

		await raceStore.load(id, false, { addData: ['raceClassList', 'totalContestants'] });
        raceStore.checkRace();
        const { user } = userStore;
        const { language = appStateLanguage } = user;
        raceStore.checkAllPositions(user);
        const { race, raceClassList } = raceStore;
        const { hasStarted } = raceStore;
        const infoList = [
            {
                id: 1,
                icon: 'fa-duotone fa-circle-info',
                title: infoText,
                body: (language === 'en' ? race.bodyEn : null) || race.info,
                images: [],
            },
            {
                id: 11,
                icon: 'fa-duotone fa-list',
                title: programText,
                body: (language === 'en' ? race.programEn : null) || race.program,
                images: [],
            },
            {
                id: 12,
                icon: 'fa-duotone fa-tent',
                title: checkpointsInfoText,
                body: (language === 'en' ? race.checkpointsInfoEn : null) || race.checkpointsInfo,
                images: [],
            },
            {
                id: 2,
                icon: 'fa-duotone fa-square-list',
                title: termsText,
                body: (language === 'en' ? race.termsEn : null) || race.terms,
                images: [],
            },
            {
                id: 3,
                icon: 'fa-duotone fa-mountains',
                title: trackText,
                body: (language === 'en' ? race.trackInfoEn : null) || race.trackInfo,
                images: [],
            },
            {
                id: 4,
                icon: 'fa-duotone fa-book-section',
                title: rulesText,
                body: (language === 'en' ? race.rulesEn : null) || race.rules,
                images: [],
            },
            {
                id: 5,
                icon: 'fa-duotone fa-user-doctor',
                title: veterinaryText,
                body: (language === 'en' ? race.veterinaryInfoEn : null) || race.veterinaryInfo,
                images: [],
            },
            {
                id: 6,
                icon: 'fa-duotone fa-trophy',
                title: priceText,
                body: (language === 'en' ? race.pricesEn : null) || race.prices,
                images: [],
            },
            {
                id: 7,
                icon: 'fa-duotone fa-square-check',
                title: qualifyText,
                body: (language === 'en' ? race.qualifyingEn : null) || race.qualifying,
                images: [],
            },
            {
                id: 8,
                icon: 'fa-duotone fa-file-signature',
                title: declarationText,
                body: (language === 'en' ? race.declarationEn : null) || race.declaration,
                images: [],
            },
            {
                id: 9,
                icon: 'fa-duotone fa-exclamation-triangle',
                title: emergencyText,
                body: (language === 'en' ? race.emergencyInfoEn : null) || race.emergencyInfo,
                images: [],
            },
            {
                id: 10,
                icon: 'fa-duotone fa-square-list',
                title: termsOfSaleText,
                body: (language === 'en' ? race.vippsTermsOfSaleEn : null) || race.vippsTermsOfSale,
                images: [],
            },
        ].filter(i => i.body);

        this.setState({
            infoList,
            resultList: [
                {
                    id: 1,
                    icon: 'fa-duotone fa-list',
                    title: (race.showResults || hasStarted) ? resultText : signedupText,
                    url: `/race/${id}/results`,
                    images: [],
                },
                {
                    id: 2,
                    icon: 'fa-duotone fa-map-location-dot',
                    title: trackingText,
                    url: `/race/${id}/tracking`,
                    images: [],
                },
                {
                    id: 3,
                    icon: 'fa-duotone fa-square-rss',
                    title: newsText,
                    url: `/race/${id}/news`,
                    images: [],
                },
                {
                    id: 4,
                    icon: 'fa-duotone fa-tv',
                    title: streamingText,
                    url: `/race/${id}/streaming`,
                    images: [],
                },
            ],
            adminList: [
                {
                    id: 1,
                    icon: 'fa-duotone fa-pen',
                    title: editRaceText,
                    url: `/race/${id}/admin/edit`,
                    images: [],
                    // styles: 'background-color: #ff0000;',
                    classNames: 'text-danger',
                },
                {
                    id: 2,
                    icon: 'fa-duotone fa-list',
                    title: signupsText,
                    url: `/race/${id}/admin/signups`,
                    images: [],
                    classNames: 'text-danger',
                },
                {
                    id: 3,
                    icon: 'fa-duotone fa-edit',
                    title: addRaceResultText,
                    url: `/race/${id}/admin/raceresults`,
                    images: [],
                    classNames: 'text-danger',
                },
                {
                    id: 4,
                    icon: 'fa-duotone fa-edit',
                    title: checkinKioskText,
                    url: `/race/${id}/admin/checkinKiosk`,
                    images: [],
                    classNames: 'text-danger',
                },
                {
                    id: 5,
                    icon: 'fa-duotone fa-edit',
                    title: dashboardText,
                    url: `/race/${id}/admin/dashboard`,
                    images: [],
                    classNames: 'text-danger',
                },
            ],
            timetrackerList: [
                {
                    id: 1,
                    icon: 'fa-duotone fa-edit',
                    title: addRaceResultText,
                    url: `/race/${id}/admin/raceresults`,
                    images: [],
                    classNames: 'text-danger',
                },
            ],
            raceleaderList: [
                {
                    id: 1,
                    icon: 'fa-duotone fa-edit',
                    title: addRaceResultText,
                    url: `/race/${id}/admin/raceresults`,
                    images: [],
                    classNames: 'text-danger',
                },
                {
                    id: 2,
                    icon: 'fa-duotone fa-edit',
                    title: addRaceEventText,
                    url: `/race/${id}/admin/raceevent`,
                    images: [],
                    classNames: 'text-danger',
                },
            ],

        });
	}

    async loadRaceClassContestants(props = this.props) {
		const { raceClassContestantStore } = props.stores;
        const { id } = props;

        await raceClassContestantStore.load({
            query: {
                race: id,
            },
            addData: [],
        });
        raceClassContestantStore.getCountries();
    }


    loadAll = (props = this.props) => {
        this.loadRace(props);
        this.loadRaceClassContestants(props);
    }

    viewText = e => {
        // console.log('viewText');
        const { drawerLevel = 1 } = this.props;
        const { id } = e.target.closest('.box-line').dataset;
        // const { id } = e.target.closest('').dataset;

        const { infoList } = this.state;
        const info = infoList.find(i => i.id === parseInt(id, 10));


		const { appState } = this.props.stores;
		const { drawerHeightMedium } = appState;
		appState.openDrawer('viewText', {
			height: drawerHeightMedium,
			title: info.title,
            body: info.body,
            images: info.images,
		}, drawerLevel + 1);
	}


    addToCalendar = e => {
        const {
            raceSignupStartText,
            raceSignupEndText,
            raceStartText,
        } = this.props;
        const { drawerLevel = 1 } = this.props;
        const { raceStore } = this.props.stores;
        const { race = {}, raceClassList } = raceStore;

		const { appState } = this.props.stores;
		const { drawerHeightSmall, apiServer } = appState;
        const events = [];
        if (new Date(race.signupStartDate) > new Date()) {
            events.push(
                {
                    startTime: race.signupStartDate,
                    endTime: race.signupStartDate,
                    title: `${race.title}: ${raceSignupStartText}`,
                    description: race.body ? race.body : '',
                    location: race.location ? race.location : '',
                    url: `${apiServer}/#/race/${race.id}`,
                },
            );
        }
        if (new Date(race.signupEndDate) > new Date()) {
            events.push(
                {
                    startTime: race.signupEndDate,
                    endTime: race.signupEndDate,
                    title: `${race.title}: ${raceSignupEndText}`,
                    description: race.body ? race.body : '',
                    location: race.location ? race.location : '',
                    url: `${apiServer}/#/race/${race.id}`,
                },
            );
        }
        if (new Date(race.endDate) > new Date()) {
            events.push(
                {
                    startTime: race.startDate,
                    endTime: race.endDate,
                    title: `${race.title}: ${raceStartText}`,
                    description: race.body ? race.body : '',
                    location: race.location ? race.location : '',
                    url: `${apiServer}/#/race/${race.id}`,
                },
            );
        }

		appState.openDrawer('addToCalendar', {
			height: drawerHeightSmall,
			// title: 'Add to calendar',
            events,
		}, drawerLevel + 1);
	}

    goToPage = e => {
        const { id } = e.target.closest('.box-line').dataset;
        const { resultList } = this.state;
        const res = resultList.find(i => i.id === parseInt(id, 10));
        if (res.url) {
            route(res.url);
        }
    }

    goToAdminPage = e => {
        const { id } = e.target.closest('.box-line').dataset;
        const { adminList } = this.state;
        const res = adminList.find(i => i.id === parseInt(id, 10));
        if (res.url) {
            route(res.url);
        }
    }

    goToTimetrackerPage = e => {
        const { id } = e.target.closest('.box-line').dataset;
        const { timetrackerList } = this.state;
        const res = timetrackerList.find(i => i.id === parseInt(id, 10));
        if (res.url) {
            route(res.url);
        }
    }

    goToRaceleaderPage = e => {
        const { id } = e.target.closest('.box-line').dataset;
        const { raceleaderList } = this.state;
        const res = raceleaderList.find(i => i.id === parseInt(id, 10));
        if (res.url) {
            route(res.url);
        }
    }

    goToTracking = e => {
        const { id } = this.props;
        route(`/race/${id}/tracking`);
    }

    goToResults = e => {
        const { id } = this.props;
        route(`/race/${id}/results`);
    }

    goToNews = e => {
        const { id } = this.props;
        route(`/race/${id}/news`);
    }

    goToStreaming = e => {
        const { id } = this.props;
        route(`/race/${id}/streaming`);
    }

    sortByTotal = (a, b) => {
        const { raceClassContestantStore } = this.props.stores;
        const { countriesTotal } = raceClassContestantStore;
        return countriesTotal[b] - countriesTotal[a];
    }

    componentDidMount() {
        this.loadAll();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.id !== this.props.id) {
            this.loadAll(nextProps);
        }
    }

    componentWillUnmount() {

    }

    render() {
        const { infoList, resultList, adminList, timetrackerList, raceleaderList } = this.state;
        const { appState, userStore, raceStore, raceClassContestantStore } = this.props.stores;
        const {
            titleText,
            raceStartText,
            signupStartText,
            distanceText,
            dogsText,
            restText,
            totalFeeText,
            signupFeeText,
        } = this.props;
        const { isDevelopment, path, apiServer } = appState;
        const { language: appStateLanguage } = appState;
        const { user, isAdmin } = userStore;
        const { language = appStateLanguage } = user;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');
        const { race = {}, raceClassList, raceClassContestant, isRaceAdmin, isTimeTracker, isTdLeader, isRaceLeader, hasStarted } = raceStore;
        const { showResults } = race;
        const isRaceMode = hasStarted || showResults;
        const { countriesTotal } = raceClassContestantStore;

        return (<>
            <div class='w-100 d-flex flex-sm-row flex-column'>
                {isRaceMode ? <>
                    <div class='w-100 d-flex flex-column'>
                        <div class='w-100 font-weight-lighter px-3 box-header d-flex flex-row justify-content-between'>
                            <span>{race.title}</span>
                        </div>
                        <div class='w-100 font-weight-lighter px-3 d-flex flex-row justify-content-between'>
                            <span>{util.isoDateCompact(race.startDate)} - {util.isoDateCompact(race.endDate)}</span>
                        </div>
                    </div>
                </> : <>
                    <div class='w-100 d-flex flex-column'>
                        <div class='d-flex px-3'>
                            <ImageScroller images={race.images} stores={this.props.stores} showImg={img => displayImg(img, this.props, '1920x')} />
                        </div>
                        <div class='font-weight-lighter px-3 box-header d-flex flex-row justify-content-between'>
                            {race.title}
                        </div>
                        <div class={`d-flex flex-sm-row flex-column mx-2`}>

                            <GuiInfoBox
                                stores={this.props.stores}
                                nameMiddle={raceStartText}
                                value={util.formatDate(race.startDate, { hour12: false, hour: '2-digit', minute: '2-digit', locale: 'nb-NO' })}
                                // postfix={util.formatDate(race.endDate, { hour12: false, hour: '2-digit', minute: '2-digit' })}
                                styles={'font-size: 1.5em;'}
                                classesContainer={'flex-fill'}
                            />

                            {race.signupStartDate && <>
                                <GuiInfoBox
                                    stores={this.props.stores}
                                    nameMiddle={signupStartText}
                                    value={util.formatDate(race.signupStartDate, { hour12: false, hour: '2-digit', minute: '2-digit', locale: 'nb-NO' })}
                                    // postfix={util.formatDate(race.signupStartDate, { hour12: false, hour: '2-digit', minute: '2-digit' }, true)}
                                    classesContainer={'flex-fill'}
                                />
                            </>}
                        </div>
                    </div>
                </>}

                {/* <div class='w-100 d-flex flex-column'>
                    <div class='font-weight-lighter px-3 box-header d-flex flex-row justify-content-between'>
                        <Text id='race.history.title'>Your history</Text>
                    </div>
                    <div class={`d-flex flex-column mx-3 px-2 py-3 box-container ${darkmode ? 'bg-darkmode' : 'bg-lightmode'}`}>
                        Your history
                    </div>
                </div> */}
            </div>

            {race.ingress && <div class='w-100 d-flex flex-sm-row flex-column mt-2'>
                <div class='w-100 d-flex flex-column my-2'>
                    <div class={`d-flex flex-column mx-3 px-3 py-3 box-container ${darkmode ? 'bg-darkmode' : 'bg-lightmode'}`}>
                        <Markdown markdown={localUtil.replaceImages(race.ingress, race.images, language, false)} markedOpts={localUtil.getMarkdownOptions()} />
                    </div>
                </div>
            </div>}

            <div class='w-100 d-flex flex-sm-row flex-column mt-5'>
                <div class='w-100 d-flex flex-column position-relative'>
                    <div class='d-flex flex-row position-absolute justify-content-between overflow-auto no-scrollbar' style='max-width: 50%; top: -12px; right: 15px;'>
                        {countriesTotal && Object.keys(countriesTotal).sort(this.sortByTotal).map(country => {
                            return (<>
                                <div class='d-flex flex-row justify-content-center align-items-center position-relative'>
                                    {localUtil.getFlagSvgByCountryCode(country, 45, false, 'rounded-lg', 'border: 2px solid #ffffff;')}
                                    <div class='position-absolute bg-primary text-white rounded-lg px-2' style='bottom: 0px; left: 0px; line-height: 16px; border: 2px solid #ffffff;'>
                                        <small>
                                            {countriesTotal[country]}
                                        </small>
                                    </div>
                                </div>
                            </>);
                        })}
                    </div>
                    <RaceClassList
                        stores={this.props.stores}
                        raceClasses={raceClassList}
                        hideEmpty={true}
                        showCevron={true}
                    />
                    {/* <div class='font-weight-lighter px-3 box-header d-flex flex-row justify-content-between'>
                        <Text id='race.classes.title'>Classes</Text>
                    </div>
                    <div class={`d-flex flex-column mx-3 px-2 py-3 box-container ${darkmode ? 'bg-darkmode' : 'bg-lightmode'}`}>
                    </div> */}
                </div>

                {/* <div class='w-100 d-flex flex-column my-2'>
                    <div class='font-weight-lighter px-3 box-header d-flex flex-row justify-content-between'>
                        <Text id='race.map.title'>Map</Text>
                    </div>
                    <div class={`d-flex flex-column mx-3 px-2 py-3 box-container ${darkmode ? 'bg-darkmode' : 'bg-lightmode'}`}>
                        Map
                    </div>
                </div> */}
            </div>

            {isRaceMode && <>
                <div class='w-100 d-flex flex-sm-row flex-column mt-1'>
                    <button type='button' class='btn btn-primary flex-fill rounded-pill mt-2 mx-3 my-0' onClick={this.goToTracking}>
                        <i class='fa-duotone fa-map-location-dot' /> <Text id='race.tracking'>Tracking</Text>
                    </button>
                    <button type='button' class='btn btn-primary flex-fill rounded-pill mt-2 mx-3 my-0' onClick={this.goToResults}>
                        <i class='fa-duotone fa-list-ol' /> <Text id='race.results-menu'>Results</Text>
                    </button>
                    <button type='button' class='btn btn-primary flex-fill rounded-pill mt-2 mx-3 my-0' onClick={this.goToNews}>
                        <i class='fa-duotone fa-newspaper' /> <Text id='race.news'>News</Text>
                    </button>
                    {race.showStreaming && <button type='button' class='btn btn-primary flex-fill rounded-pill mt-2 mx-3 my-0' onClick={this.goToStreaming}>
                        <i class='fa-duotone fa-film' /> <Text id='race.streaming'>Streaming</Text>
                    </button>}
                </div>

                {race.showStreaming && <StreamingList stores={this.props.stores} raceId={race.id} frontpage={1} classNames={'mt-3'} header={'Streaming'} />}

                <div class='w-100 d-flex flex-sm-row flex-column mt-3'>
                    <div class='w-100 d-flex flex-column'>
                        <div class='font-weight-lighter px-3 box-header d-flex flex-row justify-content-between'>
                            <Text id='race.news'>News</Text>
                        </div>
                        <div class={`d-flex flex-column mx-3 px-0 py-3 box-container ${darkmode ? 'bg-darkmode' : 'bg-lightmode'}`}>
                            <NewsList
                                stores={this.props.stores}
                                raceId={race.id}
                                largeList={false}
                                showHeader={false}
                                limit={10}
                            />
                        </div>
                        <div class='w-100 d-flex justify-content-center'>
                            <a href={`/race/${race.id}/news`}><Text id='race.see-all-news'>See all news</Text> &raquo;</a>
                        </div>
                    </div>
                    <div class='w-100 d-flex flex-column'>
                        <div class='font-weight-lighter px-0 box-header d-flex flex-row justify-content-between'>
                            <Text id='race.results-menu'>Results</Text>
                        </div>
                        <div class={`d-flex flex-column mx-3 px-3 py-3 box-container ${darkmode ? 'bg-darkmode' : 'bg-lightmode'}`}>
                            <RaceResultList
                                stores={this.props.stores}
                                id={race.id}
                            />
                        </div>
                    </div>
                </div>
            </>}

            {!isRaceMode && <>
                <div class='w-100 d-flex flex-sm-row flex-column mt-5'>
                    <div class='w-100 d-flex flex-column'>
                        <div class='font-weight-lighter px-3 box-header d-flex flex-row justify-content-between'>
                            <Text id='race.news'>News</Text>
                        </div>
                        <div class={`d-flex flex-column mx-3 px-0 py-3 box-container ${darkmode ? 'bg-darkmode' : 'bg-lightmode'}`}>
                            <NewsList
                                stores={this.props.stores}
                                raceId={race.id}
                                largeList={false}
                                showHeader={false}
                                limit={3}
                            />
                            <div class='d-flex flex-row justify-content-center border-top mt-2 pt-2'>
                                <a href={`/race/${race.id}/news`}>
                                    <Text id='race.see-all-news'>See all news</Text> &raquo;
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </>}

            {isTimeTracker && <>
                <div class='w-100 d-flex flex-sm-row flex-column mt-5'>
                    <div class='w-100 d-flex flex-column'>
                        <Localizer>
                            <GuiList
                                title={<span class='text-danger font-weight-normal'><Text id='race.timetracker'>Race timetracker</Text></span>}
                                stores={this.props.stores}
                                holdingStore={raceStore}
                                view={this.goToTimetrackerPage}
                                list={timetrackerList}
                                elTitle={e => e.title}
                                elInfo1={e => ''}
                                elInfo2={e => ''}
                                elInfo3={e => ''}
                                hideEmpty={true}
                                showEmptyImage={false}
                                showFilterBox={false}
                                showCevron={true}
                                // editElement={editElement}
                            />
                        </Localizer>
                    </div>
                </div>
            </>}
            {(isRaceLeader || isTdLeader) && <>
                <div class='w-100 d-flex flex-sm-row flex-column mt-5'>
                    <div class='w-100 d-flex flex-column'>
                        <Localizer>
                            <GuiList
                                title={<span class='text-danger font-weight-normal'><Text id='race.raceleader'>Race Leader / TD Leader</Text></span>}
                                stores={this.props.stores}
                                holdingStore={raceStore}
                                view={this.goToRaceleaderPage}
                                list={raceleaderList}
                                elTitle={e => e.title}
                                elInfo1={e => ''}
                                elInfo2={e => ''}
                                elInfo3={e => ''}
                                hideEmpty={true}
                                showEmptyImage={false}
                                showFilterBox={false}
                                showCevron={true}
                                // editElement={editElement}
                            />
                        </Localizer>
                    </div>
                </div>
            </>}

            {isRaceAdmin && <>
                <div class='w-100 d-flex flex-sm-row flex-column mt-5'>
                    <div class='w-100 d-flex flex-column'>
                        <Localizer>
                            <GuiList
                                title={<span class='text-danger font-weight-normal'><Text id='race.admin'>Race admin</Text></span>}
                                stores={this.props.stores}
                                holdingStore={raceStore}
                                view={this.goToAdminPage}
                                list={adminList}
                                elTitle={e => e.title}
                                elInfo1={e => ''}
                                elInfo2={e => ''}
                                elInfo3={e => ''}
                                hideEmpty={true}
                                showEmptyImage={false}
                                showFilterBox={false}
                                showCevron={true}
                                // editElement={editElement}
                            />
                        </Localizer>
                    </div>
                </div>
            </>}

            <div class='w-100 d-flex flex-sm-row flex-column mt-5'>
                <div class='w-100 d-flex flex-column'>
                    <Localizer>
                        <GuiList
                            title={<Text id='race.results'>Race results</Text>}
                            stores={this.props.stores}
                            holdingStore={raceStore}
                            view={this.goToPage}
                            list={resultList}
                            elTitle={e => e.title}
                            elInfo1={e => ''}
                            elInfo2={e => ''}
                            elInfo3={e => ''}
                            hideEmpty={false}
                            showEmptyImage={false}
                            showFilterBox={false}
                            showCevron={true}
                            // editElement={editElement}
                        />
                    </Localizer>
                </div>
            </div>

            <div class='w-100 d-flex flex-sm-row flex-column mt-5'>
                <div class='w-100 d-flex flex-column'>
                    <Localizer>
                        <GuiList
                            title={<Text id='race.about'>About the race</Text>}
                            stores={this.props.stores}
                            holdingStore={raceStore}
                            view={this.viewText}
                            list={infoList}
                            elTitle={e => e.title}
                            elInfo1={e => ''}
                            elInfo2={e => ''}
                            elInfo3={e => ''}
                            hideEmpty={true}
                            showEmptyImage={false}
                            showFilterBox={false}
                            showCevron={true}
                            // editElement={editElement}
                        />
                    </Localizer>
                </div>
            </div>

            <div class='w-100 d-flex flex-sm-row flex-column mt-5 px-3'>
                <button
                    type='button'
                    class='btn btn-primary btn-lg btn-block rounded-pill'
                    onClick={this.addToCalendar}
                >
                    <i class='fa-solid fa-calendar-plus mr-1' /> <Text id='action.add-to-calendar'>Add to calendar</Text>
                </button>
            </div>

        </>);
    }
}

export default RaceDetail;



